import { createAction, props } from '@ngrx/store'
import { OrderItem } from 'src/app/interfaces/cart.interface'

export enum cartActionTypes {
	addItemToCart = '[Cart] add one item to cart',
	updateItemToCart = '[Cart] update an item in cart',
	updateCartTimestamp = '[Cart] update cart timestamp',
	clearCart = '[Cart] empty the cart',
	updateCart = '[Cart] update items in cart',
	listCart = '[Cart] display list of cart items',
}

export const addItemToCart = createAction(cartActionTypes.addItemToCart, props<{ item: OrderItem }>())
export const updateItemToCart = createAction(cartActionTypes.updateItemToCart, props<{ item: OrderItem }>())
export const updateCart = createAction(cartActionTypes.updateCart, props<{ items: OrderItem[] }>())
export const updateCartTimestamp = createAction(cartActionTypes.updateCartTimestamp)
export const clearCart = createAction(cartActionTypes.clearCart)
export const listCart = createAction(cartActionTypes.listCart)
