<ng-container *ngIf="show">
	<div
		class="app-loading py-3"
		[ngClass]="{
			'app-loading-overlay': overlay,
			'app-loading--custom': color
		}"
		[ngStyle]="{
			'text-align': align
		}"
	>
		<tag-loading
			[ngStyle]="{
				width: width,
				height: height
			}"
		></tag-loading>
	</div>
</ng-container>
