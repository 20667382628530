import { Observable, of } from 'rxjs'
import { take } from 'rxjs/operators'

import { Store } from '@ngrx/store'
import { Actions, ofType } from '@ngrx/effects'

import { Injectable } from '@angular/core'
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http'

import { AppState } from '../store/reducers'

import { ModalService } from './modal.service'

import { environment } from '../../environments/environment'

import { menuClear, menuError, menuLoad } from '../store/actions/menu.action'

import { ScheduledDeactivation } from '../interfaces/scheduledDeactivation.interface'


export interface Menu {
	_id: string
	name: {
		pt: string
		en: string
		fr: string
		es: string
	}
	descript: {
		pt: string
		en: string
		fr: string
		es: string
	}
	price: Number
	promoPrice: Number
	prices: [
		{
			label: string
			price: string
			promoPrice: string
		}
	]
	slug: string
	backingId: string
	type: string
	avatarUrl: string
	backgroundUrl: string
	disabled: false
	contentType: string
	currency: string
	menuItems: any[]
	venues: any[]
	menus: any[]
	parent: string
	parentCardapio: string
	isCardapio: false
	created_at: string,
	scheduledDeactivation: ScheduledDeactivation[]
}

export interface MenuState {
	updatedAt: Date
	loading: boolean
	menus: Menu[]
	error: HttpErrorResponse | null
}

@Injectable({ providedIn: 'root' })
export class MenuService {
	private menu: any
	private selectedMenu: any

	menu$: Observable<MenuState> = this.store.select('menu')

	constructor(
		private http: HttpClient,
		private updates$: Actions,
		private store: Store<AppState>,
		private modalService: ModalService
	) {}

	public getMenuFromVenue(venueId: string, appLabel: string): Observable<any> {
		return this.menu
			? of(this.menu)
			: this.http.get<any>(`${environment.liveMenuApi.host}/menu/${venueId}/${appLabel}?ignoreDisabled=1`, {
					headers: new HttpHeaders({
						Accept: '*/*',
						'Content-Type': 'application/json',
					}),
			  })
	}

	public getMenu(): any {
		return this.menu
	}

	public setMenu(menu: any): void {
		this.menu = menu
	}

	public getSelectedMenu(): any {
		return this.selectedMenu
	}

	public setSelectedMenu(menuId: any): void {
		this.selectedMenu = menuId
	}

	public loadMenu(venueId: string): void {
		const menuError$ = this.updates$.pipe(ofType(menuError), take(1))

		this.store.dispatch(menuLoad({ id: venueId }))

		menuError$.subscribe(res => {
			this.modalService.open('app-modal-menu-error')
			console.error(res.payload.error)
		})
	}

	public clearMenu(): void {
		this.store.dispatch(menuClear())
	}
}
