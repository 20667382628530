import { Router } from '@angular/router'
import { Component, Input } from '@angular/core'

@Component({
	selector: 'app-snackbar',
	templateUrl: './snackbar.component.html',
	styleUrls: ['./snackbar.component.scss'],
})
export class SnackbarComponent {
	@Input() message: string
	@Input() subtitle: string = null
	@Input() routeTo: string = null
	@Input() type: string

	constructor(private readonly router: Router) {}

	closeAlert() {
		const snackBar = document.getElementById('snackbar')
		snackBar.className = snackBar.className.replace('fade-in-fade-out', '')
	}

	goTo() {
		if (this.routeTo) {
			this.router.navigate([this.routeTo])
		}
	}
}
