import { Injectable } from '@angular/core'

export interface notificationOptions {
	title?: string
	icon?: string
	tag?: string
	body?: string
	data?: any
}

const initialState: notificationOptions = {
	title: 'Tagme Live Menu',
	icon: '../../assets/favicons/icon-192x192.png',
	body: '',
	tag: 'tagme-dine-in',
	data: {},
}

@Injectable({
	providedIn: 'root',
})
export class PushNotificationService {
	constructor() {}

	showNotification(options: notificationOptions = initialState) {
		if (window.Notification && Notification.permission === 'granted') {
			navigator.serviceWorker.ready.then(function (registration) {
				registration.showNotification(options.title, {
					body: options.body,
					icon: options.icon,
					badge: '../../assets/icons/monocromatic-72x72.png',
					vibrate: [200, 100, 200, 100, 200, 100, 200],
					tag: options.tag,
					data: options.data,
				})
			})
		}
	}
}
