import { Injectable } from '@angular/core'
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'

import { AuthService } from '../services/auth.service'
import { VenueService } from 'src/app/services/venue.service'

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
	constructor(private router: Router, private authService: AuthService, private venueService: VenueService) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		const isLiveMenuPro = this.venueService.hasLiveMenuPro()
		const currentUser = this.authService.getCurrentUser
		const storedVenueId = this.venueService.getVenueId

		if (!isLiveMenuPro) {
			if (storedVenueId) {
				this.router.navigate(['/menu/', storedVenueId])
			} else {
				this.router.navigate(['/'])
			}
			return false
		}

		if (currentUser.isAuthenticated || state.url === '/login') {
			// logged in so return true
			return true
		}

		// not logged in so redirect to login page with the return url
		this.authService.refusedToLogin(false)

		if (storedVenueId) {
			this.router.navigate(['/menu/', storedVenueId], { queryParams: { returnUrl: state.url } })
		} else {
			this.router.navigate(['/'])
		}

		return false
	}
}
