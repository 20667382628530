import { SelectivePreloadingStrategyService } from './services/selective-preloading-strategy.service'
import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { AuthGuard } from './helpers/auth.guard'

const routes: Routes = [
	{ path: 'menu', loadChildren: () => import('./modules/menu/menu.module').then(m => m.MenuModule) },
	{
		path: 'pdf',
		loadChildren: () => import('./modules/contingency/contingency.module').then(m => m.ContingencyModule),
	},
	{
		path: 'cart',
		canActivate: [AuthGuard],
		loadChildren: () => import('./modules/cart/cart.module').then(m => m.CartModule),
	},
	{
		path: 'cards',
		canActivate: [AuthGuard],
		loadChildren: () => import('./modules/account/cards/cards.module').then(m => m.CardsModule),
	},
	{
		path: 'orders',
		canActivate: [AuthGuard],
		loadChildren: () => import('./modules/order/order.module').then(m => m.OrderModule),
	},
	{
		path: 'login',
		canActivate: [AuthGuard],
		loadChildren: () => import('./modules/account/login/login.module').then(m => m.LoginModule),
	},
	{
		path: 'pay',
		canActivate: [AuthGuard],
		loadChildren: () => import('./modules/pay/pay.module').then(m => m.PayModule),
	},
	{ path: 'terms', loadChildren: () => import('./modules/terms/terms.module').then(m => m.TermsModule) },
	{
		path: '',
		loadChildren: () => import('./modules/start-page/start-page.module').then(m => m.StartPageModule),
	},
	{
		path: '**',
		loadChildren: () => import('./modules/not-found/not-found.module').then(m => m.NotFoundModule),
	},
]

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			enableTracing: false, // <-- debug only
			preloadingStrategy: SelectivePreloadingStrategyService,
			scrollPositionRestoration: 'enabled',
			anchorScrolling: 'enabled',
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
