import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'

import { of } from 'rxjs'
import { map, catchError, exhaustMap } from 'rxjs/operators'

import { LiveMenuSettingsService } from '../../services/live-menu-settings.service'

import { liveMenuLoad, liveMenuSuccess, liveMenuError } from '../actions/live-menu-settings.action'

@Injectable()
export class LiveMenuSettingsEffects {
	constructor(private actions$: Actions, private liveMenuSettingsService: LiveMenuSettingsService) {}

	loadLiveMenu$ = createEffect(() =>
		this.actions$.pipe(
			ofType(liveMenuLoad),
			exhaustMap(action =>
				this.liveMenuSettingsService.getLiveMenuSettingsByVenueId(action.venueId).pipe(
					map(liveMenu =>
						liveMenuSuccess({
							payload: {
								loading: false,
								...liveMenu,
							},
						})
					),
					catchError(error => of(liveMenuError(error)))
				)
			)
		)
	)
}
