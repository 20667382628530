import { createAction, props } from '@ngrx/store'

import { LiveMenuOrder } from '../../interfaces/livemenu-order.interface'
import { TableOrderState } from '../../interfaces/livemenu-table-order.interface'

export enum tableOrderActionTypes {
	tableOrderLoad = '[TableOrder] Load Table Order',
	tableOrderUpdate = '[TableOrder] updated TableOrder',
	tableOrderClear = '[TableOrder] empty the TableOrder',
	tableOrderFail = '[TableOrder] Failing to handle TableOrder',
	tableOrderSuccess = '[TableOrder] Success loading TableOrder',
	tableOrderSetCurrentTable = '[TableOrder] updated TableOrder Table Number',
}

export const tableOrderLoad = createAction(
	tableOrderActionTypes.tableOrderLoad,
	props<{ venueId: string; tableId: number }>()
)

export const tableOrderUpdate = createAction(
	tableOrderActionTypes.tableOrderUpdate,
	props<{ tableOrder: LiveMenuOrder }>()
)

export const tableOrderSetCurrentTable = createAction(
	tableOrderActionTypes.tableOrderSetCurrentTable,
	props<{ table: number }>()
)

export const tableOrderClear = createAction(tableOrderActionTypes.tableOrderClear)

export const tableOrderFail = createAction(tableOrderActionTypes.tableOrderFail, props<{ tableId: string }>())

export const tableOrderSuccess = createAction(
	tableOrderActionTypes.tableOrderSuccess,
	props<{ payload: TableOrderState }>()
)
