import { TranslateLoader } from '@ngx-translate/core'
import { from, Observable } from 'rxjs'

// https://github.com/ngx-translate/http-loader/issues/25
// It forces i18next to load translations' JSON from assets files, avoiding undesired cache
export class WebpackTranslateLoader implements TranslateLoader {
	getTranslation(lang: string): Observable<any> {
		return from(import(`../../assets/i18n/${lang}.json`))
	}
}
