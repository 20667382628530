export interface SocialAnalytics {
	venueId?: string
	facebookPixel?: string
	googleAnalytics?: string
	googleTagManager?: string
}

export const arrayAnalytics: SocialAnalytics[] = [
	// Tagme
	{ venueId: '56c776ff0896b3cd13c6012b', googleTagManager: 'GTM-P5S9GBM' },

	// Gattopardo
	{ venueId: '5841c7abf1fb67ed11c289e9', googleTagManager: 'GTM-5XSXPGD' },

	// Amaral
	{ venueId: '586e5b57a3770501e048fa2e', googleTagManager: 'GTM-5XSXPGD' },

	// Giuseppe
	{ venueId: '5e961708e130c46f6bd4afc4', facebookPixel: '1023121447788683' },

	// Nolita
	{ venueId: '5aba949bfa271c9ee3c3ea28', facebookPixel: '2695268910574721' },

	// Vasto
	{ venueId: '5e3ae6a0e130c46f6b35fc6c', facebookPixel: '2345355012432512' },

	// Sushi leblon
	{ venueId: '5b7b136dfa271c9ee3c4ec83', facebookPixel: '939013776600280' },

	// Nola
	{ venueId: '5b7b0e66fa271c9ee3c4ec80', facebookPixel: '339832257158083' },

	// 348
	{ venueId: '56c777110896b3cd13c601de', googleAnalytics: 'AW-443327621' },
	{ venueId: '572258fff1fb67ed11c16941', googleAnalytics: 'AW-443327621' },
	{ venueId: '56c777130896b3cd13c601ec', googleAnalytics: 'AW-443327621' },
	{ venueId: '56c777140896b3cd13c601fd', googleAnalytics: 'AW-443327621' },
	{ venueId: '56c777190896b3cd13c6020c', googleAnalytics: 'AW-443327621' },

	// Vila Chalezinho
	{
		venueId: '61042dad39f8fe0011f207e4',
		googleTagManager: 'GTM-KCL7G54',
		facebookPixel: '3159499317394367',
	},
	{
		venueId: '5a86d6f4fa271c9ee3c3a55b',
		googleTagManager: 'GTM-KCL7G54',
		facebookPixel: '3159499317394367',
	},
	{
		venueId: '5afee766fa271c9ee3c47006',
		googleTagManager: 'GTM-KCL7G54',
		facebookPixel: '3159499317394367',
	},

	// Funiki Sushi
	{ venueId: '607ede86ba14f12f6424f872', facebookPixel: '223702816301488' },

	// Abbraccio
	{ venueId: '5fff743e864a953bace2e71c', googleTagManager: 'GTM-TFGCDF3' }, //group
	{ venueId: '5f2067887e46d0ea71dc8a8b', googleTagManager: 'GTM-TFGCDF3' },
	{ venueId: '5f231b4f9b65772da7201fce', googleTagManager: 'GTM-TFGCDF3' },
	{ venueId: '5f3c05f040f1fdf4c9e9b462', googleTagManager: 'GTM-TFGCDF3' },
	{ venueId: '5f3c30cba748257e56b0984f', googleTagManager: 'GTM-TFGCDF3' },
	{ venueId: '5f3c328635f8708568237973', googleTagManager: 'GTM-TFGCDF3' },
	{ venueId: '5f3c33a5568f658aa4327595', googleTagManager: 'GTM-TFGCDF3' },
	{ venueId: '5f3d37a078714b814dd699e5', googleTagManager: 'GTM-TFGCDF3' },
	{ venueId: '5f3d40a55b5c499b007cc463', googleTagManager: 'GTM-TFGCDF3' },
	{ venueId: '5f3d47c33aecccad19bae633', googleTagManager: 'GTM-TFGCDF3' },
	{ venueId: '5f3d8137336a4163513d9929', googleTagManager: 'GTM-TFGCDF3' },
	{ venueId: '5f3d8f467a85610138ad2b8f', googleTagManager: 'GTM-TFGCDF3' },
	{ venueId: '5f3d9932b8ee00014385c863', googleTagManager: 'GTM-TFGCDF3' },

	//Otbk
	{ venueId: '5f8de58570a20810ec180d77', googleTagManager: 'GTM-NPKPHFF' }, //group
	{ venueId: '5ca3aaf822db8f849ea3b8fe', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f23197b085ad7259b8e01ef', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f281515be0506065b0051cd', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f7376aefd690e013c8b2bcd', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f73783b90373007e3937acc', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f738059fc410220024a79f2', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f7381b90848d6236efed81b', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f73842375158a2c97ed4f44', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f73a3a3b712f795475ae0d7', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f73a46c87a5189795b7faed', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f73a5027eb3199980de4e6a', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f73a5f74649349c8ea36340', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f74dc1d3c415c748d8185bc', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f74dfe2ee1a828058466a51', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f74e075f523e481f7935211', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f74e15ad402588485110860', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f74e1fed1e4ac86177ce5f1', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f74e400df371e8ba57778c6', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f74e4badcd3578e85c06f25', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f74e586e77c6e90e5206c5d', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f74e6ef82ecb2949ed6ffa6', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f74e8f33c3a59998a14ef21', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f766bca511c3502df0887aa', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f766c53b1c1b702eaed42f3', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f766cc275281d02f53da3ba', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f766d2d9125fc030095c4be', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f766db5aaeaa4030b11c36c', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f766e1f37db2f031622676e', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f766e7a34516503219ce018', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f766f047ee654032c0366d1', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f766f62ec7630033736167b', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f766ff86b9ce2034243a3ac', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f7670584dc27e034dc179ac', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f7670bee5fa5a0358dbdf17', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f7671694508c8036e665cca', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f76737507ccc4038480a4c7', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f776e3bdf088cd3f1f403a1', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f776ef4463e5cd7bad57d3b', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f776f888370edd95ad96749', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f77703fcb1af7dc7c0a7dba', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f7770d0ad4efeddfcf3503a', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f777142115dd2df4f3693dc', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f77755feca990ea777e7fcb', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f77763e52df65ed8731572e', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f7776cc44cc79f02ecacfbe', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f77797d8a4df8f6fa7afc1e', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f777cc38846f701129e7df5', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f777d7639404402f5afbfc8', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f777df5fd8b280485efdbe6', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f777fc6f0319109551ddfb3', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f7cb7cf3063123c77aefdf8', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f7cb87d36e00c3ee3cc7058', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f7cb9212c2bda40d9fb161e', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f7cb9b567141342cc4a22ac', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f7cba56bc522b4480cf24b4', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f7cbb19d4747b46b466c671', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f7cbc251333b94b4e77e747', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f7cc29d6a136709fd315dea', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f7cc333695d2b0a08dbd455', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f7cc39f37deaf0a13b6bf33', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f7cc3ffe73f3f0a1e77d4dd', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f7cc45d1f1dce0a2936280f', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f7cc9e2700e870a34948e0f', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f7cca5ac8cc430a3fe96149', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f7ddb7e51a8dd0a762880c5', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f7ddd92a79cd30a8c9f6415', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f7dde0b4d1f250a97425839', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f7dde7acf16c90aa28ffcaf', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f7ddee3f67dbe0aadad6708', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f7ddf4b9249770ab81e6b3e', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f7dfdbe10185a0acef61241', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f7dfe4d3ebe770ad9183612', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f7dfed72d4bb90ae42d0c7c', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f7dff90af8f8f0aef0cc9e7', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f7e0072ed8d2a0afa57afe8', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f7e010b21a6360b05d36576', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f7e01966aa0d50b1bd8ce26', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f7e01fb6317ce0b26431214', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f7e02786258600b31d878fb', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f7e0a24a190710b52653f94', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f7e0a996420090b5dfe123f', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f7e0b25f4948a0b68bb55e6', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f7e0b8bc5d8150b737f797e', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f7e0bf4198de40b7e95cd16', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f7e0c794efaa80b89c1220d', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f7e0cfa2272ad0b94385fe2', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f7e10e6fccbe30baab54fd8', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f7f28cab8979a3c464d5db8', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f7f2f115c8d701e8b91f5c9', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f7f312206c4fc29b30804e4', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f7f323732fcb52caf53758b', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f7f389b049cc93c38bf3429', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f7f393b0bbb123e197d06af', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f7f39febb7c283feee28d69', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f7f3a9a33823e41b8e8024d', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f7f3b5274af684360205faf', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f7f3c78bafc8246533b5bd4', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f7f4f561d8c2587836a2f51', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f7f58aadcd672ad8aa26130', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f7f5960198a1db131037bb4', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f7f5b92959b14b72ffc06bd', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f7f5c2edd07b3b91b4caa39', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f807488d84384c66d82bb00', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f8076b48cd6e4ccdaa1ec2c', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f807999272d3cd5926cd7de', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5f8de58570a20810ec180d77', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5fae88da6e93610036fabb10', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5fc50295aac7265ec0c5c0fc', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5fc50729b56e2b64f88af35c', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5ff8a8e1532fc457e82058ca', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '5ff8ade3e13447581cbcd979', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '6035356260fb800b50a75d54', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '603e8b8f8ffb832dccb73e1d', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '6070b06cfe08053b58823ba1', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '607d85197b84f128406b8eda', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '60a2c0cd25fea14b2ceb236f', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '60be2f112fc7041de8619965', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '60be2f221d460144ec685d47', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '6126a78a8b7ad900202fce1b', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '6126a79dda57340021868523', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '613b546c0df350002c06522f', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '6154acdd948ffa004164edb2', googleTagManager: 'GTM-NPKPHFF' },
	{ venueId: '6172f562f1286c0011a6f09d', googleTagManager: 'GTM-NPKPHFF' },

	// Kibo Japanese Bar / Cuisine
	{
		venueId: '61a784022e30a4002193323c',
		facebookPixel: '1079889682859669',
		googleTagManager: 'GTM-PRGNVQW',
	},
	{
		venueId: '61a7799725fe9d002136f13a',
		facebookPixel: '1079889682859669',
		googleTagManager: 'GTM-PRGNVQW',
	},

	// Vineria1976
	{ venueId: '5ab5571bfa271c9ee3c3e6f3', facebookPixel: '538242814727410' },

	// Ocyá
	//{ venueId: '625433f960685e0012759735', facebookPixel: '254551456785767', googleTagManager: 'GTM-52XFH9Z' },

	// Grupo PHD
	{ venueId: '62cd78f1275a630011afd072', facebookPixel: '573129751199179' },

	// Tetto Rooftop Lounge
	{ venueId: '62c8365d291ff90020fdab63', facebookPixel: '989486231717163' },

	// Pippo Limone
	{ venueId: '62bb557192c32200127f9ce7', facebookPixel: '427372422618030' },

	// Aragon Espírito Ibérico
	{ venueId: '628e244df0c2260011ab8df6', facebookPixel: '796611761719631' },

	// Antonella Maison
	{ venueId: '6290e2f1b5eb200011d000bc', facebookPixel: '1133914527334662' },

	// Mr. Wong
	{ venueId: '62bddd34e9e2930011d177e9', facebookPixel: '600745078140485' },

	// Sky Hall Terrace Bar
	{ venueId: '5e2210c50a4ababa6db800b0', facebookPixel: '900211544497093' },

	//Bar do Victor - São Lourenço
	{
		venueId: '56c776f00896b3cd13c600ba',
		googleTagManager: 'GTM-P6CR9HW',
		facebookPixel: '770857186431704',
	},

	// Praça do Victor - Praça Espanha
	{
		venueId: '56c776f10896b3cd13c600c8',
		googleTagManager: 'GTM-P6CR9HW',
		facebookPixel: '770857186431704',
	},

	// Greem | Novotel São Paulo Morumbi
	{ venueId: '613f5546f0c0ec002062162f', facebookPixel: '697920368496526' },

	// Grupo Applebee's
	{ venueId: '61b0a6091ed0f3002cc9aa7a', facebookPixel: '862678398281639' }, // Atibaia
	{ venueId: '59a9aa9fd1d80760d550142b', facebookPixel: '862678398281639' }, // Eldorado
	{ venueId: '59b2f8e7d1d80760d5501798', facebookPixel: '862678398281639' }, // Granja Viana
	{ venueId: '59a871d0d1d80760d5501359', facebookPixel: '862678398281639' }, // Moema
	{ venueId: '59ad9fe1d1d80760d5501527', facebookPixel: '862678398281639' }, // Morumbi
	{ venueId: '6256e9ec3c567c0011634403', facebookPixel: '862678398281639' }, // Outlet fernão Dias
	{ venueId: '59b7fbe6d1d80760d5501956', facebookPixel: '862678398281639' }, // Outlet Premium
	{ venueId: '59aedba0d1d80760d550161d', facebookPixel: '862678398281639' }, // Iguatemi Campinas

	// Bar da Usina
	{ venueId: '63b86690f0590c0052a2d4c8', googleTagManager: 'GTM-KSJVF8H' },

	// Bar da Usina - Filiais
	{ venueId: '63b86621b035ce0051dbdf08', googleTagManager: 'GTM-5WS2LWH' }, // Vila Valqueire
	{ venueId: '63b86642f288dd0052732c81', googleTagManager: 'GTM-5WS2LWH' }, // Nova Iguaçu
	{ venueId: '63b86686819602005210c63e', googleTagManager: 'GTM-5WS2LWH' }, // Freguesia
	{ venueId: '63b86634322cc5005284dbcb', googleTagManager: 'GTM-5WS2LWH' }, // Campo Grande

	// Macelleria Quitéria
	{
		venueId: '63ac5656f288dd005271bcdf',
		googleTagManager: 'GTM-MVZD9PC',
		googleAnalytics: 'G-XDM2MTTMH1',
	},

	// Porchetteria
	{
		venueId: '621d005f4174b100110ad451',
		facebookPixel: '608066098005630',
		googleTagManager: 'GTM-NX4H863',
	},

	// Apelo Piadineria
	{ venueId: '63c14dc1d7b86c0052ac2f58', facebookPixel: '230956206273440' },

	// NERD'S Comida & Diversão
	{ venueId: '63d140cca0a18100527289d5', facebookPixel: '238516052015418' },

	// Udon
	{
		venueId: '6459528bfbb82c0055283e88',
		facebookPixel: '1246216442778403',
		googleTagManager: 'GTM-MP4VWT4',
	}, // Mercure
	{
		venueId: '645954b41951c40055a35c6b',
		facebookPixel: '1246216442778403',
		googleTagManager: 'GTM-MP4VWT4',
	}, // Belvedere

	// Meat's Grill Steak House
	{ venueId: '622797816af01800118018dc', facebookPixel: '2122674691262556' },

	// Zaituna Gastronomia
	{ venueId: '6478dd0588f854004fe654e7', facebookPixel: '102405426225484' },

	// La Gare
	{ venueId: '615cae36948ffa0041670cae', facebookPixel: '785823853144785' },

	// Barravento
	{ venueId: '6491c3b46c89f1005336a06c', facebookPixel: '1112169362538972' },

	// Restaurante Raiz Caipira
	{ venueId: '64340bfd2ad1e4005c3634f1', facebookPixel: '325266812320337' },

	// O Compadre
	{ venueId: '5de7e48c0a4ababa6dd099d9', facebookPixel: '3242220592701165' },

	// Allegria Trancoso
	{ venueId: '639b51da1e68d200525f5997', facebookPixel: '1289784331626344' },

	// Maracutaia Bar e Restaurante
	{ venueId: '64a6c5ba6c89f10053385423', googleTagManager: 'GTM-579G72T' },

	// Takêdo
	{ venueId: '580f6974f1fb67ed11c1ff02', facebookPixel: '1955861737849047' },

	// Gurumê
	{ venueId: '56c777520896b3cd13c603dc', facebookPixel: '581979189051182' }, // Fashion Mall
	{ venueId: '5d25f2770a4ababa6db45949', facebookPixel: '581979189051182' }, // Ipanema
	{ venueId: '5c543af89fb620aa38a3f2dd', facebookPixel: '581979189051182' }, // Rio Sul
	{ venueId: '643da1c636739a00555c5d1d', facebookPixel: '581979189051182' }, // Rio Design Leblon
	{ venueId: '643da1c636739a00555c5d1d', facebookPixel: '581979189051182' }, // Rio Design Leblon
	{ venueId: '5d7297e70a4ababa6d27c034', facebookPixel: '581979189051182' }, // RBD
	{ venueId: '5da612ab0a4ababa6ddee6fc', facebookPixel: '581979189051182' }, // Tijuca

	//Soul Jazz Burger Santa Tereza
	{ venueId: '64ad7161b0d60f00549f0599', facebookPixel: '856589565889745' },

	//Olegário
	{ venueId: '649f1e87e13ae000553c252b', googleTagManager: 'GTM-MP4VWT4' }, //Olegário Alphaville
	{ venueId: '649f1e25b2ea4200553353bf', googleTagManager: 'GTM-MP4VWT4' }, //Olegário Jardins
	{ venueId: '649f1e6ff484b300528932d4', googleTagManager: 'GTM-MP4VWT4' }, //Olegário Loudes
	{ venueId: '649f1df86c89f1005337a7fd', googleTagManager: 'GTM-MP4VWT4' }, //Olegário Pátio
	{ venueId: '649f1e59b04c4c00553baa9c', googleTagManager: 'GTM-MP4VWT4' }, //Olegário Praia do Canto
	{ venueId: '649f1e43cedf0b0055eaa833', googleTagManager: 'GTM-MP4VWT4' }, //Olegário Vila da Serra
	{ venueId: '64765b3845e07a00556b6df9', googleTagManager: 'GTM-MP4VWT4' }, //Shizu Restaurante Japonês

	// Santo Mar Santana
	{ venueId: '645559ea1951c40055a2f60c', facebookPixel: '838893284459700' },

	//Bonitto Trancoso
	{ venueId: '63750dcdd14ec0004a1fefd0', facebookPixel: '1404895813402130' },

	//Favoritto Trancoso
	{ venueId: '6306674a904265001190b56e', facebookPixel: '581612373981908' },

	//Mamma Jamma
	{ venueId: '5cc786ff65009908e4ac3947', googleTagManager: 'GTM-T7WD2XX' }, //GRUPO
	{ venueId: '64dbd066a93538004ff0f6d1', googleTagManager: 'GTM-T7WD2XX' }, //Mamma Jamma Botafogo Praia Shopping
	{ venueId: '64dbcdc4a93538004ff0f672', googleTagManager: 'GTM-T7WD2XX' }, //Mamma Jamma Campinas
	{ venueId: '5adf898bfa271c9ee3c44ef5', googleTagManager: 'GTM-T7WD2XX' }, //Mamma Jamma Casa&Gourmet
	{ venueId: '57a23761f1fb67ed11c19aa9', googleTagManager: 'GTM-T7WD2XX' }, //Mamma Jamma CasaShopping
	{ venueId: '5ccd820922db8f849ea428d8', googleTagManager: 'GTM-T7WD2XX' }, //Mamma Jamma Icaraí
	{ venueId: '64d3b964228053004ed6d35e', googleTagManager: 'GTM-T7WD2XX' }, //Mamma Jamma NewYorkCityCenter
	{ venueId: '64dbcf342276a40052b77447', googleTagManager: 'GTM-T7WD2XX' }, //Mamma Jamma NorteShopping
	{ venueId: '5f737d21f13efb16a8f4c071', googleTagManager: 'GTM-T7WD2XX' }, //Mamma Jamma Plaza Niterói
	{ venueId: '594990ead1d80760d54ff1ce', googleTagManager: 'GTM-T7WD2XX' }, //Mamma Jamma Recreio Shopping
	{ venueId: '5ffeff8f864a953bace2d15b', googleTagManager: 'GTM-T7WD2XX' }, //Mamma Jamma Rio Design Barra
	{ venueId: '64dbce9fe7dd2c004ffdb799', googleTagManager: 'GTM-T7WD2XX' }, //Mamma Jamma Rio Sul
	{ venueId: '5bbe14d7fa271c9ee3c573e0', googleTagManager: 'GTM-T7WD2XX' }, //Mamma Jamma Shopping Barra
	{ venueId: '64dbd0f3a93538004ff0f6e3', googleTagManager: 'GTM-T7WD2XX' }, //Mamma Jamma Shopping Tijuca (não aceitam reservas)

	//Astor
	{ venueId: '645bde5bf8307e0056c186ee', googleTagManager: 'GTM-NPD7GWJ' }, //Astor
	{ venueId: '64524f1db252e60056772ce9', googleTagManager: 'GTM-NPD7GWJ' }, //Astor Vila Madalena
	{ venueId: '6453e3b236739a00555e405d', googleTagManager: 'GTM-NPD7GWJ' }, //Astor Shopping JK
	{ venueId: '6453eb740653a50055da50ed', googleTagManager: 'GTM-NPD7GWJ' }, //Astor Oscar Freire
	{ venueId: '64b7e0d12987cd00505731a7', googleTagManager: 'GTM-NPD7GWJ' }, //Astor Paulista

	//SubAstor
	{ venueId: '645bfa6393c09d0056176875', googleTagManager: 'GTM-5GP6MJ9' }, //SubAstor
	{ venueId: '64513977e74a9f0055b72ef7', googleTagManager: 'GTM-5GP6MJ9' }, //SubAstor Bar do Cofre
	{ venueId: '6453c51993c09d005616b234', googleTagManager: 'GTM-5GP6MJ9' }, //SubAstor Vila Madalena
	{ venueId: '64b7ff10c4ead2004e167f63', googleTagManager: 'GTM-5GP6MJ9' }, //SubAstor Paulista

	//Bráz Elettrica
	{ venueId: '62ebdddade681e0011f054eb', googleTagManager: 'GTM-NCQ7T94' }, //Bráz Elettrica Pizza Moema Pro
	{ venueId: '62ec20b9de681e0011f08736', googleTagManager: 'GTM-NCQ7T94' }, //Bráz Elettrica Moema

	//Bráz Pizzaria
	{ venueId: '6421ffbf22df6f005cdfd176', googleTagManager: 'GTM-53GLJSS' }, //Bráz Pizzaria
	{ venueId: '641367829f8470005c9759a2', googleTagManager: 'GTM-53GLJSS' }, //Bráz Pizzaria Pinheiros
	{ venueId: '641374a633ddd5005caa4ad2', googleTagManager: 'GTM-53GLJSS' }, //Bráz Pizzaria Tatuapé
	{ venueId: '641461c12d96d7005c07c2fd', googleTagManager: 'GTM-53GLJSS' }, //Bráz Pizzaria Moema
	{ venueId: '641467ca2d96d7005c07c35f', googleTagManager: 'GTM-53GLJSS' }, //Bráz Pizzaria Jardim Botânico
	{ venueId: '64146e8c9f8470005c976bb2', googleTagManager: 'GTM-53GLJSS' }, //Bráz Pizzaria Campinas
	{ venueId: '6414957d56ee0d005c1adcff', googleTagManager: 'GTM-53GLJSS' }, //Bráz Pizzaria Paraíso
	{ venueId: '64149af333ddd5005caa6800', googleTagManager: 'GTM-53GLJSS' }, //Bráz Pizzaria Vila Mariana
	{ venueId: '6414a11b700f98005c1d15b7', googleTagManager: 'GTM-53GLJSS' }, //Bráz Pizzaria Perdizes
	{ venueId: '6414a5bbced4d4005ca5564b', googleTagManager: 'GTM-53GLJSS' }, //Bráz Pizzaria Higienópolis

	// Casa Bráz Cidade Jardim
	{ venueId: '641328d333ddd5005caa4058', googleTagManager: 'GTM-MQFRGZP' },

	//Câmara Fria
	{ venueId: '64c810ec27e2850053349b38', googleTagManager: 'GTM-M3B7CFV' },

	//Brasserie
	{ venueId: '6450fc8156f185005ad5dff4', googleTagManager: 'GTM-MLS2C9N' }, //ICI Brasserie Jardins
	{ venueId: '6450fca336739a00555e0f8c', googleTagManager: 'GTM-MLS2C9N' }, //ICI Brasserie Shopping JK
	{ venueId: '6451160956f185005ad5e18c', googleTagManager: 'GTM-MLS2C9N' }, //ICI Brasserie Villa Lobos
	{ venueId: '645a3cc2b252e6005677d631', googleTagManager: 'GTM-MLS2C9N' }, //ICI Brasserie

	//Lanchonete da Cidade
	{ venueId: '638f6c0610eead004a4507cf', googleTagManager: 'GTM-596R9S9' }, //Lanchonete da Cidade
	{ venueId: '62ec20ac2a004e0012550dd1', googleTagManager: 'GTM-596R9S9' }, //Lanchonete da Cidade Moema
	{ venueId: '6380cd0a824d17004ae6a0bc', googleTagManager: 'GTM-596R9S9' }, //Lanchonete da Cidade Jardins
	{ venueId: '6384a48016a302004ae7bdb7', googleTagManager: 'GTM-596R9S9' }, //Lanchonete da Cidade Shopping Cidade Jardim
	{ venueId: '6385fceee424ed004a9664c6', googleTagManager: 'GTM-596R9S9' }, //Lanchonete da Cidade Higienópolis
	{ venueId: '638612120c7c3a004ab290cb', googleTagManager: 'GTM-596R9S9' }, //Lanchonete da Cidade Pinheiros

	//Pirajá
	{ venueId: '64ca6905e5f5b30053b0c737', googleTagManager: 'GTM-TLTGTCK' }, //Pirajá
	{ venueId: '64c3b958df8c3700552d2760', googleTagManager: 'GTM-TLTGTCK' }, //Pirajá Center Norte
	{ venueId: '64c7bfebae3f310055b24cdd', googleTagManager: 'GTM-TLTGTCK' }, //Pirajá Tamboré
	{ venueId: '64c7c6a028c3890053497757', googleTagManager: 'GTM-TLTGTCK' }, //Pirajá Villa Lobos
	{ venueId: '64c7ca6128c38900534977b0', googleTagManager: 'GTM-TLTGTCK' }, //Pirajá Itaim
	{ venueId: '64c7cf94e5f5b30053b09bfb', googleTagManager: 'GTM-TLTGTCK' }, //Pirajá Eldorado
	{ venueId: '64c7d555e5f5b30053b09ca4', googleTagManager: 'GTM-TLTGTCK' }, //Pirajá Morumbi
	{ venueId: '64c7f01bae3f310055b25173', googleTagManager: 'GTM-TLTGTCK' }, //Pirajá Campinas
	{ venueId: '64c7f53a228053004ed5da06', googleTagManager: 'GTM-TLTGTCK' }, //Pirajá Alphaville
	{ venueId: '64c7fb6ff4cca00054b7735c', googleTagManager: 'GTM-TLTGTCK' }, //Pirajá Paulista
	{ venueId: '64c8066fdf8c3700552da34b', googleTagManager: 'GTM-TLTGTCK' }, //Pirajá Faria Lima
	{ venueId: '64c80cf5ae3f310055b25416', googleTagManager: 'GTM-TLTGTCK' }, //Pirajá Pinheiros

	//Soul Jazz Burguer
	{ venueId: '64adb748c4ead2004e1589af', facebookPixel: '856589565889745' }, //Soul Jazz Burguer Pampulha

	//Restaurante Micale
	{ venueId: '63ab420ec2b0270052dd07c7', googleTagManager: 'GTM-KMWNWSHV' },

	//Cultura Gastrobar
	{ venueId: '63b6cb79f0590c0052a29707', facebookPixel: '1338093710421632' },

	//Bodega do Sertão
	{ venueId: '643d5ad81951c40055a0e396', googleTagManager: 'GTM-N33PHT2Z' },

	//Janga
	{ venueId: '63ef8457d1f91700529fa208', googleTagManager: 'GTM-WQ6RR7PW' }, //Janga Shopping
	{ venueId: '5ddd94270a4ababa6d0e88b5', googleTagManager: 'GTM-PGLNZFG5' }, //Janga Praia

	//Ticino Queijo & Fogo
	{ venueId: '64ada851b0d60f00549f0df0', googleTagManager: 'GTM-THNDQ32G' },

	//L'angolo Italiano
	{ venueId: '6501b184cb79c600534366d9', googleTagManager: 'GTM-KQ5QM9KP' },

	//La Braise
	{ venueId: '64d53954ae3f310055b37396', googleTagManager: 'GTM-MPL8P3SZ' },

	//Grupo Eataly
	{ venueId: '6176fafb50e0020011627c39', googleTagManager: 'GTM-WH8L6D3' }, //Pasta e Pizza
	{ venueId: '6176f058cedfe200113bd2c0', googleTagManager: 'GTM-WH8L6D3' }, //Brace
	{ venueId: '6176e74a1f15c400208bb465', googleTagManager: 'GTM-WH8L6D3' }, //Trattoria
	{ venueId: '6176f5e91f15c400208bb548', googleTagManager: 'GTM-WH8L6D3' }, //Bistecca

	//Meet & Eat
	{ venueId: '62ffd74ddaf31500126b3e29', facebookPixel: '298650229233596' },

	//Unidades Fantástico
	{
		venueId: '63e5155ad22bb9005224a5d3',
		googleTagManager: 'GTM-5C5H7367',
		facebookPixel: '1565867663940771',
	}, //Fantástico Irajá Shop. Via Brasil
	{
		venueId: '6215223f3f7ab8001109dc3f',
		googleTagManager: 'GTM-5C5H7367',
		facebookPixel: '1565867663940771',
	}, //Fantástico Taquara / Jacarepaguá

	//Grupo Chalézinho
	{ venueId: '614399e20c56a20020d25356', facebookPixel: '2521188574865199' }, //O Italiano Restaurante
	{ venueId: '643d5b1ef8307e0056bed919', facebookPixel: '1519736211866024' }, //Hacienda 1979
	{ venueId: '64526862fbb82c00552799bf', facebookPixel: '878113256012711' }, //Cozinha de Fogo Wals
]
