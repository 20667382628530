import { HttpHeaders } from '@angular/common/http'
import { TranslateService } from '@ngx-translate/core'
import { createNgModule, Injectable, Injector, NgModuleRef, Type } from '@angular/core'

import { environment } from '../../environments/environment'

export type SnackbarMessage = {
	type: 'green' | 'red'
	title: string
	body: string
	routeTo: string | null
}

@Injectable({
	providedIn: 'root',
})
export class AppService {
	public header = {
		headers: new HttpHeaders({
			apitoken: environment.reservationWidgetApi.apitoken,
		}),
		'Content-Type': 'application/json',
	}

	snackbarMessage: SnackbarMessage = {
		title: this._translateService.instant('snackbarMessage.default.title'),
		body: this._translateService.instant('snackbarMessage.default.body'),
		routeTo: '/orders',
		type: 'green',
	}

	constructor(private _injector: Injector, private _translateService: TranslateService) {}

	createMessageFromWS(action: string, params?: SnackbarMessage) {
		this.snackbarMessage.body = ''

		switch (action.toLowerCase()) {
			case 'accept':
				this.snackbarMessage.title = this._translateService.instant('snackbarMessage.accept.title')
				this.snackbarMessage.body = this._translateService.instant('snackbarMessage.accept.body')
				this.snackbarMessage.type = 'green'
				break

			case 'receive':
				this.snackbarMessage.title = this._translateService.instant('snackbarMessage.receive.title')
				this.snackbarMessage.type = 'green'
				break

			case 'pay':
				this.snackbarMessage.title = this._translateService.instant('snackbarMessage.pay.title')
				this.snackbarMessage.type = 'green'
				break

			case 'totally-pay':
				this.snackbarMessage.title = this._translateService.instant('snackbarMessage.totallyPay.title')
				this.snackbarMessage.type = 'green'
				break

			case 'pay-cancel':
				this.snackbarMessage.title = this._translateService.instant('snackbarMessage.payCancel.title')
				this.snackbarMessage.type = 'red'
				break

			case 'pay-request-cancel':
				this.snackbarMessage.title = this._translateService.instant('snackbarMessage.payRequestCancel.title')
				this.snackbarMessage.type = 'red'
				break

			case 'cancel':
				this.snackbarMessage.title = this._translateService.instant('snackbarMessage.cancel.title')
				this.snackbarMessage.type = 'red'
				break

			case 'delivered':
				this.snackbarMessage.title = this._translateService.instant('snackbarMessage.delivered.title')
				this.snackbarMessage.type = 'green'
				break

			case 'refuse':
				this.snackbarMessage.title = this._translateService.instant('snackbarMessage.refuse.title')
				this.snackbarMessage.type = 'red'
				break

			case 'cancel-item':
				this.snackbarMessage.title = this._translateService.instant('snackbarMessage.cancelItem.title')
				this.snackbarMessage.type = 'red'
				break

			case 'refuse-item':
				this.snackbarMessage.title = this._translateService.instant('snackbarMessage.refuseItem.title')
				this.snackbarMessage.type = 'red'
				break

			case 'login-success':
				this.snackbarMessage.title = this._translateService.instant('snackbarMessage.loginSuccess.title')
				this.snackbarMessage.body = this._translateService.instant('snackbarMessage.loginSuccess.body')
				this.snackbarMessage.type = 'green'
				this.snackbarMessage.routeTo = null
				break
		}

		return this.snackbarMessage
	}

	customSnackbarMessage(params: SnackbarMessage) {
		this.snackbarMessage.title = params.title
		this.snackbarMessage.body = params.body
		this.snackbarMessage.type = params.type
		this.snackbarMessage.routeTo = params.routeTo
	}

	activateSnackbar(): void {
		const snackBar = document.getElementById('snackbar')
		snackBar.classList.add('fade-in-fade-out')
	}

	openLazyLoadedComponent<T>(importedFile: T, componentToOpen: Type<unknown>, destination): NgModuleRef<T> {
		const module: Type<T> = (<any>importedFile)[Object.keys(importedFile)[0]]
		const moduleRef: NgModuleRef<T> = createNgModule(module, this._injector)
		return destination.createComponent(componentToOpen, { ngModuleRef: moduleRef })
	}
}
