import { createAction, props } from '@ngrx/store'

import { TableOrderPerUserState } from '../../interfaces/IOrdersPerUser.interface'

export enum tableOrderPerUserActionTypes {
	tableOrderPerUserLoad = '[TableOrderPerUser] Load Table Order Per User',
	tableOrderPerUserUpdate = '[TableOrderPerUser] Updated Table Order Per User',
	tableOrderPerUserFail = '[TableOrderPerUser] Failing to handle Table Order Per User',
	tableOrderPerUserSuccess = '[TableOrderPerUser] Success loading Table Order Per User',
	tableOrderPerUserClear = '[TableOrderPerUser] empty Table Order Per User',
}

export const tableOrderPerUserLoad = createAction(
	tableOrderPerUserActionTypes.tableOrderPerUserLoad,
	props<{ venueId: string; tableId: number }>()
)

export const tableOrderPerUserUpdate = createAction(
	tableOrderPerUserActionTypes.tableOrderPerUserUpdate,
	props<{ payload: TableOrderPerUserState }>()
)

export const tableOrderPerUserSuccess = createAction(
	tableOrderPerUserActionTypes.tableOrderPerUserSuccess,
	props<{ payload: TableOrderPerUserState }>()
)

export const tableOrderPerUserFail = createAction(
	tableOrderPerUserActionTypes.tableOrderPerUserFail,
	props<{ tableId: string }>()
)

export const tableOrderPerUserClear = createAction(tableOrderPerUserActionTypes.tableOrderPerUserClear)
