// import the interface
import { MenuState } from '../../services/menu.service'
import { menuActionTypes } from '../actions/menu.action'

const initialState: MenuState = {
	updatedAt: new Date(),
	loading: true,
	menus: [],
	error: null,
}

export function MenuReducer(state: MenuState = initialState, action: any) {
	switch (action.type) {
		case menuActionTypes.menuSuccess:
			return {
				...state,
				...action.payload,
			}
		case menuActionTypes.menuError:
			return {
				...initialState,
				error: action.payload.error,
			}
		case menuActionTypes.menuClear:
			return initialState
		default:
			return state
	}
}
