<app-loading-overlay *ngIf="!isContingency" [overlay]="true"></app-loading-overlay>

<router-outlet (activate)="stopLoading()"></router-outlet>

<app-snackbar *ngIf="!isContingency" [message]="snackbarMessage.title" [subtitle]="snackbarMessage.body"
	[routeTo]="snackbarMessage.routeTo" [type]="snackbarMessage.type">
</app-snackbar>

<app-modal id="app-modal-menu-error" class="modal-menu-error" [preventOutsideClickClose]="true" *ngIf="!isContingency">
	<div class="modal-menu-error__header">
		<h1>{{ 'menuError.header' | translate }}</h1>
	</div>

	<div class="modal-menu-error__content">
		<p>{{ 'menuError.message' | translate }}</p>
	</div>

	<tg-button (click)="sendToLiveMenuQRCode()" class="modal-menu-error__button" color="var(--primary-color)"
		text="{{ 'ok' | translate }}" customStyles="border-radius: var(--rounded-tagme-buttons);"></tg-button>
</app-modal>

<app-modal *ngIf="!isContingency" id="app-modal-info" class="modal-info" [preventOutsideClickClose]="true">
	<div class="modal-info__header">
		<h1>{{ 'tableClosedSuccessfully' | translate }}</h1>
	</div>

	<div class="modal-info__content">
		<p>{{ 'weHopeYourExperienceWasPerfect' | translate }}</p>
		<p class="modal-info__content__comeBackOften">{{ 'thankYouAndComeBackOften' | translate }}</p>
	</div>

	<tg-button (click)="closeModalLogout()" class="modal-info__button" color="var(--primary-color)"
		text="{{ 'close' | translate }}" customStyles="border-radius: var(--rounded-tagme-buttons);"></tg-button>
</app-modal>

<app-modal id="modal-order-canceled-refused" class="modal-order-canceled-refused" [showCloseButton]="true"
	*ngIf="!isContingency">
	<div class="modal-order-canceled-refused__img">
		<img src="../assets/images/status/ilustra-erro.svg" alt="Houve um problema" width="250" height="250" />
	</div>

	<div class="modal-order-canceled-refused__texts">
		<div class="modal-order-canceled-refused__texts__info">
			<p class="paragraph-color">{{ 'orderCanceledRefused' | translate }}</p>
		</div>

		<div class="modal-order-canceled-refused__texts__reason">
			<p class="paragraph-color">{{ reasonOrderCanceledRefused }}</p>
		</div>
	</div>

	<div class="modal-order-canceled-refused__buttons">
		<tg-button type="outline-main" text="{{ 'back' | translate }}" (click)="closeModal('modal-order-canceled-refused')"
			customStyles="{{ 'border-color: var(--primary-color); color: var(--primary-color); border-radius: var(--rounded-tagme-buttons);' }}"></tg-button>
	</div>
</app-modal>

<app-modal id="modal-pdv-error" class="modal-pdv-error" *ngIf="!isContingency" [showCloseButton]="false"
	[preventOutsideClickClose]="true">
	<div class="modal-order-canceled-refused__img">
		<img src="../assets/images/status/ilustra-erro.svg" alt="Houve um problema" width="250" height="250" />
	</div>

	<div class="modal-order-canceled-refused__texts">
		<div class="modal-order-canceled-refused__texts__info">
			<p class="paragraph-color">{{ 'pdvErrorMessage' | translate }}</p>
		</div>
	</div>
</app-modal>