import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { EMPTY, of } from 'rxjs'
import { map, catchError, exhaustMap } from 'rxjs/operators'
import { VenueService } from './../../services/venue.service'
import { venueLoad, venueSuccess, venueError } from './../actions/venue.action'

@Injectable()
export class VenueEffects {
	constructor(private actions$: Actions, private venueService: VenueService) {}

	loadVenue$ = createEffect(() =>
		this.actions$.pipe(
			ofType(venueLoad),
			exhaustMap(action =>
				this.venueService.loadVenueFromApi(action.id).pipe(
					map(venue =>
						venueSuccess({
							payload: {
								loading: false,
								...venue,
							},
						})
					),
					catchError(error => of(venueError(error)))
				)
			)
		)
	)
}
