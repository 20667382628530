import { createAction, props } from '@ngrx/store'

import { User } from '../../interfaces/IUserData'
import { IProductsUsers } from '../../interfaces/IProductsUsers'
import { ICardInterface } from '../../interfaces/ICard.interface'

export enum userActionTypes {
	userInfo = '[User] User Info',
	userLoad = '[User] User Load',
	userUpdate = '[User] User Update',
	userSuccess = '[User] User Load Success',
	userError = '[User] User Load Error',
	userRefusedToLogin = '[User] User Refused to Login',
	userLogout = '[User] User Logout',
	userUnsetRefusedToLogin = '[User] User Unset Refused to Login',
	userSetPreAuthCard = '[User] User Set Pre Auth Card',
	userRefreshToken = '[User] User Refresh Token',
}

export const userInfo = createAction(userActionTypes.userInfo)
export const userLoad = createAction(
	userActionTypes.userLoad,
	props<{ phone?: string; email?: string; code: string }>()
)
export const userUpdate = createAction(
	userActionTypes.userUpdate,
	props<{ payload: Partial<IProductsUsers> }>()
)
export const userSuccess = createAction(userActionTypes.userSuccess, props<{ payload: User }>())
export const userError = createAction(userActionTypes.userError, props<{ error: any }>())
export const userRefusedToLogin = createAction(
	userActionTypes.userRefusedToLogin,
	props<{ login: boolean }>()
)
export const userLogout = createAction(userActionTypes.userLogout)
export const userUnsetRefusedToLogin = createAction(userActionTypes.userUnsetRefusedToLogin)
export const userSetPreAuthCard = createAction(
	userActionTypes.userSetPreAuthCard,
	props<{ selectedPreAuthCard: ICardInterface }>()
)
export const userRefreshToken = createAction(
	userActionTypes.userRefreshToken,
	props<{ accessToken: string, refreshToken: string }>()
)
