import { Observable } from 'rxjs'
import { Injectable } from '@angular/core'

import { SocketService } from './socket.service'
import { LiveMenuTableOrder } from '../interfaces/livemenu-table-order.interface'

@Injectable({ providedIn: 'root' })
export class TableOrderSocketService {
	private socket

	constructor(private socketService: SocketService) {}

	public initSocket(accessToken: string, venue: string, table: number): boolean {
		if (!this.socket?.connected) {
			this.socket = this.socketService.connect('', {
				path: `/order`,
				// accessToken,
				roomName: `${venue}_${table}`,
			})

			return true
		}

		return false
	}

	public emit(evt: string, msg: string) {
		this.socket.emit(evt, { message: msg })
	}

	public onEvent(event: any): Observable<LiveMenuTableOrder | any> {
		return new Observable<LiveMenuTableOrder | any>(observer => {
			this.socket.on(event, data => observer.next(data))
		})
	}

	public closeSocket(): void {
		if (this.socket) {
			this.socket.disconnect()
		}
	}
}
