import { Observable } from 'rxjs'
import { skipWhile, take } from 'rxjs/operators'

import { Store } from '@ngrx/store'

import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'

import { venueLoad, venueSuccess } from '../store/actions/venue.action'

import { VenueState } from '../store/reducers/venue.reducers'

import { environment } from '../../environments/environment'

export interface Venue {
	loading?: boolean
	_id: string
	name: {
		pt: string
		en: string
		es: string
		fr: string
	}
	address: {
		_id?: string
		address1?: string
		number?: string
		neighborhood?: string
		city?: string
		state?: string
		country?: string
	}
	phone?: [{ name: string; value: string }]
	site?: string
	email?: string
	parent: string
	liveMenuPro: boolean
	hasMenu?: boolean
	serviceTax?: number
	slug?: string
}

export interface LiveMenuProConfig {
	enabled: boolean
}

@Injectable({
	providedIn: 'root',
})
export class VenueService {
	venue
	venueId: string
	serviceTax: number

	venue$: Observable<Venue> = this.store.select('venue').pipe(
		skipWhile(venue => !venue?._id),
		take(1)
	)

	liveMenuProConfig: LiveMenuProConfig

	constructor(private httpClient: HttpClient, private store: Store<VenueState>) {
		if (this.venue) {
			this.store.dispatch(venueSuccess({ payload: this.venue }))
		}
		this.venue$.subscribe(state => {
			this.venue = state
			this.venueId = state._id
			this.serviceTax = state.serviceTax
		})
	}

	public loadVenueFromApi(venueId: string): Observable<Venue> {
		return this.httpClient.get<Venue>(`${environment.liveMenuApi.host}/Venue/${venueId}`, {
			headers: new HttpHeaders({
				Accept: '*/*',
				'Content-Type': 'application/json',
			}),
		})
	}

	public loadVenue(venueId: string, isContingency?: boolean) {
		if (this.venue && !isContingency) {
			this.store.dispatch(venueSuccess({ payload: this.venue }))
		} else {
			this.store.dispatch(venueLoad({ id: venueId }))
		}
	}

	get getVenueId() {
		return this.venueId
			? this.venueId
			: /^[a-fA-F0-9]{24}$/.test(location.pathname.split('/')[2])
			? location.pathname.split('/')[2]
			: localStorage.getItem('venueId')
	}

	get getVenue() {
		return this.venue
	}

	get getServiceTax() {
		return this.serviceTax === null || this.serviceTax === undefined
			? 10 // Default service Tax = 10
			: this.serviceTax
	}

	firstLoadVenue() {
		return fetch(`${environment.liveMenuApi.host}/Venue/${this.getVenueId}`, {
			headers: {
				Accept: '*/*',
			},
		})
			.then(response => {
				return response?.json()
			})
			.catch(error => {
				console.error(error)
			})
	}

	loadLiveMenuProConfig(): Promise<boolean> {
		return this.firstLoadVenue().then((response: Venue) => {
			this.venue = response
			return response.liveMenuPro || false
		})
	}

	setLiveMenuProConfig(data) {
		// this.store.dispatch(venueSuccess({ payload: this.venue }))
		this.liveMenuProConfig = data
	}

	hasLiveMenuPro() {
		return this.liveMenuProConfig.enabled
	}

	public getCurrency() {
		const localVenueId = this.getVenueId
		const venuesWithEuro = [
			'620d3c3a89851500118ee46a',
			'607f1a9810a7ac81787873e0',
			'6226113505a0950012e214d8',
			'63fe607811a5ae0051d29dc1',
		]

		const venuesWithDollar = [
			'63592b42a90f29004e92b2df',
			'636136e4738ee3004a92f563',
			'63613736f73d62004a66cfac',
			'63616f1bde959d00495c3a62',
			'63616f4f5da840004aaa99fb',
		]

		switch (true) {
			case venuesWithEuro.includes(localVenueId):
				return '€'
			case venuesWithDollar.includes(localVenueId):
				return '$'
			default:
				return 'R$'
		}
	}

	public getDefaultLanguage() {
		const localVenueId = this.getVenueId
		const venuesWithEnglish = [
			'63592b42a90f29004e92b2df',
			'636136e4738ee3004a92f563',
			'63613736f73d62004a66cfac',
			'63616f1bde959d00495c3a62',
			'63616f4f5da840004aaa99fb',
			'664609ada231f5f2bb8f0c21',
		]

		switch (true) {
			case venuesWithEnglish.includes(localVenueId):
				return 'en'
			default:
				return 'pt-br'
		}
	}
}
