
export enum PaymentFormsMode {
	REQUIRED = "REQUIRED",
	DISABLED = "DISABLED"
}

export enum PaymentAntifraudMode {
	DEFAULT = "DEFAULT", // Depending on the gateway, anti-fraud is performed in case it is sent all necessary information
	DISABLED = "DISABLED"
}

export enum DeliveryConfirmationMode {
	AUTO = "AUTO",
	ASK_FOR_CONFIRMATION = "ASK_FOR_CONFIRMATION"
}

export enum AutoCloseTableOrderMode {
	DISABLED = "DISABLED",
	BY_PAY_TOTAL = "BY_PAY_TOTAL"
}

export interface LiveMenuProPaymentExternalPointOfSales {
	paymentBehavior: EPOSPaymentBehavior
	paymentApprover: EPOSPaymentApprover
}

export enum EstimatedOrderStatusTimeMode {
	NOT_ESTIMATE = 'NOT_ESTIMATE',
	BY_WAITER = 'BY_WAITER',
	BY_ITEM = 'BY_ITEM_DEFINITION',
	BY_MENU = 'BY_MENU'
}

export enum ByPassType {
	ALL = 'ALL',
	DISABLED = 'DISABLED',
	CUSTOM = 'CUSTOM',
}

export enum PaymentJourney {
	POST_PAID = 'POST_PAID',
	PRE_AUTH = 'PRE_AUTH',
	PRE_PAID = 'PRE_PAID',
	NO_PAYMENT = 'NO_PAYMENT',
}

export enum AccommodationType {
	TABLE = 'TABLE',
	ROOM = 'ROOM',
}

export enum PointOfSalesMode {
	DEFAULT = 'DEFAULT',
	EXTERNAL_TERMINAL = 'EXTERNAL',
	HYBRID = 'HYBRID',
}

export enum EPOSPaymentBehavior {
	DEFAULT = 'DEFAULT',
	CALL_WAITER = 'CALL_WAITER',
}

export enum EPOSPaymentApprover {
	NO_APPROVER = 'NO_APPROVER',
	WAITER = 'WAITER',
}

export enum PreAuthMode {
	CHECK_VALUE = 'CHECK_VALUE',
	HOLD_VALUE = 'HOLD_VALUE',
}

export enum AllowedPaymentMethods {
	CREDIT_CARD = 'CREDIT_CARD',
	DEBIT_CARD = 'DEBIT_CARD',
	PIX = 'PIX',
}

export enum RegisterCVVMode {
	FIRST_TIME = 'FIRST_TIME',
	STATIC_REQUIRE_ALWAYS = 'STATIC_REQUIRE_ALWAYS',
}

export interface LiveMenuSettingsSettings {
	callWaiter: boolean
	driveThru: boolean
	byPassRules: {
		type: ByPassType
		custom: {
			menus?: string[]
			items?: string[]
		}
	}
	oneOrderJourney?: boolean
	pdvIntegration: boolean
	paymentRules: {
		paymentJourney: PaymentJourney
		pointOfSalesMode: PointOfSalesMode
		allowedPaymentMethods: AllowedPaymentMethods[]
	}
	login?: {
		defaultType: string
	}
}

export interface LiveMenuSettingsOptionals {
	payment?: {
		preAuth?: {
			value: number
			mode: PreAuthMode
		}
		externalPointOfSales?: LiveMenuProPaymentExternalPointOfSales
		creditCard?: {
			registerCVVMode: RegisterCVVMode
			antiFraudMode?: PaymentAntifraudMode
			billingForms?: {
				address?: PaymentFormsMode
			}
		}
	},
	order?: {
		estimatedStatusTimeMode?: EstimatedOrderStatusTimeMode,
		deliveryConfirmationMode?: DeliveryConfirmationMode,
		autoCloseTableOrderMode?: AutoCloseTableOrderMode,
	},
	client?: {
		omitPrice?: {
			cart?: boolean,
			order?: boolean,
			footer?: boolean,
		}
		omitOthersClientsOrders?: boolean
	}
}

export interface LiveMenuSettingsStyleLanding {
	enabled: boolean
	images?: Array<string>
	title?: string
	subtitle?: string
}

export interface LiveMenuSettingsStyleLabeling {
	accommodation: AccommodationType
}

export interface LiveMenuSettingsStyleOrder {
	status: LiveMenuSettingsStyleOrderStatus
}

export interface LiveMenuSettingsStyleOrderStatus {
	enabled: boolean
	showEstimatedTime: boolean
	showTimer: boolean
}

export interface LiveMenuSettingsStyle {
	landing?: LiveMenuSettingsStyleLanding,
	labeling?: LiveMenuSettingsStyleLabeling,
	order?: LiveMenuSettingsStyleOrder,
}