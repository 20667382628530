import { createAction, props } from '@ngrx/store'
import { Venue } from './../../services/venue.service'

export enum venueActionTypes {
	venueLoad = '[Venue] Venue Load',
	venueSuccess = '[Venue] Venue Load Success',
	venueError = '[Venue] Venue Load Error',
	venueClear = '[Venue] Venue Clear',
}

export const venueLoad = createAction(venueActionTypes.venueLoad, props<{ id: string }>())
export const venueSuccess = createAction(venueActionTypes.venueSuccess, props<{ payload: Venue }>())
export const venueError = createAction(venueActionTypes.venueError, props<{ error: any }>())
export const venueClear = createAction(venueActionTypes.venueClear)
