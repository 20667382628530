import { Store } from '@ngrx/store'

import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'

import { Observable } from 'rxjs'
import { skipWhile, take } from 'rxjs/operators'

import { UtilsService } from './utils.service'

import { environment } from '../../environments/environment'

import { LiveMenuState } from '../store/reducers/live-menu-settings.reducers'

import { liveMenuLoad, liveMenuSuccess } from '../store/actions/live-menu-settings.action'

import {
	PaymentJourney,
	RegisterCVVMode,
	PointOfSalesMode,
	AllowedPaymentMethods,
	LiveMenuSettingsStyle,
	LiveMenuSettingsSettings,
	LiveMenuSettingsOptionals,
} from '../interfaces/live-menu-settings-enums.interface'

export interface LiveMenu {
	loading?: boolean

	_id: string
	venue: string
	pro: boolean
	settings: LiveMenuSettingsSettings
	optionals?: LiveMenuSettingsOptionals
	style?: LiveMenuSettingsStyle
}

@Injectable({
	providedIn: 'root',
})
export class LiveMenuSettingsService {
	private header = {
		headers: new HttpHeaders({
			Accept: '*/*',
			'Content-Type': 'application/json',
		}),
	}

	private liveMenu: LiveMenu

	liveMenu$: Observable<LiveMenu> = this.store.select('liveMenu').pipe(
		skipWhile(liveMenu => !liveMenu?.venue),
		take(1)
	)

	constructor(
		private _http: HttpClient,
		private utilsService: UtilsService,
		private store: Store<LiveMenuState>
	) {
		if (this.liveMenu) {
			this.store.dispatch(liveMenuSuccess({ payload: this.liveMenu }))
		}

		this.liveMenu$.subscribe(state => {
			this.liveMenu = state
		})
	}

	firstFetchLiveMenuSettings(venueId) {
		return fetch(`${environment.customersApiUrl}/livemenu/settings/getLiveMenuSettingsByVenueId/${venueId}`, {
			headers: {
				Accept: '*/*',
			},
		})
			.then(response => {
				return response?.json()
			})
			.catch(error => {
				console.error(error)
			})
	}

	firstLoadLiveMenuSettings(venueId: string) {
		return this.firstFetchLiveMenuSettings(venueId).then((response: LiveMenu) => {
			this.liveMenu = response

			return response
		})
	}

	loadLiveMenuSettings(venueId: string) {
		if (this.liveMenu) {
			this.store.dispatch(liveMenuSuccess({ payload: this.liveMenu }))
		} else {
			this.store.dispatch(liveMenuLoad({ venueId }))
		}
	}

	setLiveMenuSettings(liveMenuSettings: LiveMenu): void {
		this.liveMenu = liveMenuSettings
	}

	getLiveMenuSettingsByVenueId(venueId: string) {
		return this._http.get<any>(
			`${environment.customersApiUrl}/livemenu/settings/getLiveMenuSettingsByVenueId/${venueId}`,
			this.header
		)
	}

	getLiveMenuSettings() {
		return this.liveMenu
	}

	getLiveMenuSettingsSettings() {
		return this.liveMenu?.settings
	}

	getIsLiveMenuProOneOrderJourney(){
		return this.liveMenu?.settings?.oneOrderJourney || false
	}

	getLiveMenuSettingsOptionals() {
		return this.liveMenu?.optionals
	}

	getLiveMenuSettingsStyle() {
		return this.liveMenu?.style
	}

	getIsLiveMenuPro(): boolean {
		return this.liveMenu?.pro || false
	}

	getIsActiveCallWaiter(): boolean {
		return this.liveMenu?.settings?.callWaiter || false
	}

	getAllowedPaymentsMethods(): { id: string; label: string }[] {
		return this.liveMenu?.settings?.paymentRules?.allowedPaymentMethods
			? this.utilsService.adaptAllowedPaymentMethods(
				this.liveMenu?.settings.paymentRules.allowedPaymentMethods
			)
			: this.utilsService.adaptAllowedPaymentMethods([AllowedPaymentMethods.CREDIT_CARD])
	}

	getPaymentJourney() {
		return this.liveMenu?.settings?.paymentRules?.paymentJourney || PaymentJourney.POST_PAID
	}

	getPaymentPointOfSalesMode(): PointOfSalesMode {
		return this.liveMenu?.settings?.paymentRules?.pointOfSalesMode || PointOfSalesMode.DEFAULT
	}

	getRegisterCVVMode(): RegisterCVVMode {
		return (
			this.liveMenu?.optionals?.payment?.creditCard?.registerCVVMode || RegisterCVVMode.STATIC_REQUIRE_ALWAYS
		)
	}
}
