import { tableOrderPerUserActionTypes } from '../actions/tableOrderPerUser.action'

import { initialState, TableOrderPerUserState } from './../../interfaces/IOrdersPerUser.interface'

export function TableOrderPerUserReducer(state: TableOrderPerUserState = initialState, action: any): any {
	switch (action.type) {
		case tableOrderPerUserActionTypes.tableOrderPerUserLoad:
			return {
				...state,
			}

		case tableOrderPerUserActionTypes.tableOrderPerUserUpdate:
			return {
				...action.payload,
			}

		case tableOrderPerUserActionTypes.tableOrderPerUserSuccess:
			return {
				...action.payload,
			}

		case tableOrderPerUserActionTypes.tableOrderPerUserClear:
			return initialState

		default:
			return state
	}
}
