import { CartState, initialCartState, OrderItem } from 'src/app/interfaces/cart.interface'

import { cartActionTypes } from '../actions/cart.action'

const isEqual = (a, b) => {
	if (a === b) return true
	if (a instanceof Date && b instanceof Date) return a.getTime() === b.getTime()
	if (!a || !b || (typeof a !== 'object' && typeof b !== 'object')) return a === b
	if (a == null || b === null || b === undefined) return false
	if (a.prototype !== b.prototype) return false

	let keys = Object.keys(a)
	if (keys.length !== Object.keys(b).length) return false

	return keys.every(k => isEqual(a[k], b[k]))
}
// export const selectItems = (state: CartState) => state.items

function updateItem(items: OrderItem[], itemInput: OrderItem) {
	if (itemInput.quantity !== 0) {
		return items.map(item => (item._id === itemInput._id ? itemInput : item))
	}

	return items.filter(item => item._id !== itemInput._id)
}
/**
 * This function add one or update an item within the cart:
 * It either update one existing, or append one to the end of the array
 *
 * @param items     list of items from the cart
 * @param qty       quantity of items to be ordered
 * @param itemInput description of the item to be ordered
 * @returns an updated array with the items depending on wether or not they're are new or existing
 */
function addOneItemOrUpdate(items: OrderItem[] = [], itemInput: OrderItem) {
	return [...items, { _id: (+new Date()).toString(36), item: itemInput.item, quantity: itemInput.quantity }]
}

function updateCart(items: OrderItem[] = []) {
	return [...items]
}

/**Main reducer */
export function CartReducer(state: CartState = initialCartState, action: any): any {
	switch (action.type) {
		case cartActionTypes.listCart:
			return { ...state, loading: true, loaded: false }
		case cartActionTypes.updateCart:
			return {
				...state,
				items: updateCart(action.items),
			}
		case cartActionTypes.addItemToCart: {
			return { ...state, items: addOneItemOrUpdate(state.items, action.item) }
		}
		case cartActionTypes.updateItemToCart: {
			return { ...state, items: updateItem(state.items, action.item) }
		}
		case cartActionTypes.updateCartTimestamp: {
			return { ...state, createdAt: new Date() }
		}
		case cartActionTypes.clearCart:
			return initialCartState
		default:
			return state
	}
}
