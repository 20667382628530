import { User } from '../../interfaces/IUserData'

import { userActionTypes } from '../actions/user.action'

export interface UserState {
	refusedToLogin: boolean
	isAuthenticated: boolean
	activeSession: Date
	user: User | null
	errorMessage: string | null
}

export const initialState: UserState = {
	refusedToLogin: false,
	isAuthenticated: false,
	activeSession: null,
	user: {
		accessToken: '',
		refreshToken: null,
		userId: '',
		name: '',
		lastName: '',
		phone: '',
		email: '',
		cards: [],
		selectedPreAuthCard: null,
	},
	errorMessage: null,
}

export function UserReducer(state: UserState = initialState, action): UserState {
	switch (action.type) {
		case userActionTypes.userSuccess:
			return {
				...state,
				isAuthenticated: !!action.payload.name,
				refusedToLogin: false,
				activeSession: new Date(),
				user: action.payload,
				errorMessage: null,
			}

		case userActionTypes.userRefreshToken:
			return {
				...state,
				user: {
					...state.user,
					accessToken: action.accessToken,
					refreshToken: action.refreshToken
				}
			}

		case userActionTypes.userError:
			return {
				...initialState,
				errorMessage: action.error.message,
			}

		case userActionTypes.userUnsetRefusedToLogin:
			return {
				...initialState,
				refusedToLogin: false,
			}

		case userActionTypes.userRefusedToLogin:
			return {
				...initialState,
				refusedToLogin: action.login,
			}

		case userActionTypes.userSetPreAuthCard:
			return {
				...state,
				user: {
					...state.user,
					selectedPreAuthCard: action.selectedPreAuthCard,
				},
			}

		case userActionTypes.userLogout:
			return initialState

		default:
			return state
	}
}
