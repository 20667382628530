import { Component, ElementRef, Input, OnInit, OnDestroy } from '@angular/core'

import { ModalService } from '../../services/modal.service'

@Component({
	selector: 'app-modal',
	templateUrl: './modal.component.html',
	styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit, OnDestroy {
	@Input() id: string
	@Input() preventOutsideClickClose?: boolean | null
	@Input() bottomModal?: boolean | null = false
	@Input() showCloseButton?: boolean | null = false
	@Input() showFooter?: boolean | null = false

	private readonly element: any

	constructor(private modalService: ModalService, private el: ElementRef) {
		this.element = el.nativeElement
	}

	ngOnInit(): void {
		const modal = this

		if (!this.id) {
			console.error('modal must have an id')
			return
		}

		document.body.appendChild(this.element)

		this.element.addEventListener('click', function (e: any) {
			if (e.target.className === 'app-modal-background' && !modal.preventOutsideClickClose) {
				modal.close()
			}
		})

		// add self (this modal instance) to the modal service so it's accessible from controllers
		this.modalService.add(this)
	}

	// remove self from modal service when component is destroyed
	ngOnDestroy(): void {
		document.body.classList.remove('app-modal-open')
		this.modalService.remove(this.id)
		this.element.remove()
	}

	open(): void {
		this.element.style.display = 'block'
		this.element.classList.add('modal--open')
		document.body.classList.add('app-modal-open')
	}

	close(): void {
		this.element.classList.remove('modal--open')
		this.element.style.display = 'none'
		document.body.classList.remove('app-modal-open')
	}
}
