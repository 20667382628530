import { Store } from '@ngrx/store'
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { Observable, of, throwError } from 'rxjs'
import { catchError, map } from 'rxjs/operators'

import { environment } from '../../environments/environment'

import { AppState } from '../store/reducers'

import { cartActionTypes } from '../store/actions/cart.action'
import { tableOrderPerUserLoad } from '../store/actions/tableOrderPerUser.action'
import { tableOrderSetCurrentTable, tableOrderUpdate } from '../store/actions/tableOrder.action'

import { AuthService } from './auth.service'

import { IWaiter } from '../interfaces/IWaiter'
import { IPartialProductsUsers } from '../interfaces/IProductsUsers'
import { LiveMenuOrder } from '../interfaces/livemenu-order.interface'
import { TableOrderPerUserState } from '../interfaces/IOrdersPerUser.interface'
import { Cart, LiveMenuShoppingCartMenuItem, OrderItem } from '../interfaces/cart.interface'
import { LiveMenuTableOrder, TableOrderState } from '../interfaces/livemenu-table-order.interface'

@Injectable({
	providedIn: 'root',
})
export class TableOrderService {
	public tableOrder = {} as LiveMenuTableOrder
	tableOrder$: Observable<TableOrderState> = this.store.select('tableOrder')

	public tableOrderPerUser = {}
	tableOrderPerUser$: Observable<TableOrderPerUserState[]> = this.store.select('tableOrderPerUser')

	constructor(private _http: HttpClient, public store: Store<AppState>, private authService: AuthService) {
		this.tableOrder$.subscribe(state => {
			this.tableOrder = state?.table
		})

		this.tableOrderPerUser$.subscribe(state => {
			this.tableOrderPerUser = state
		})
	}

	/**
	 * List orders within a table (with status)
	 * @param venueId
	 * @param tableId
	 * @returns request from server with the order's status
	 */
	public getOrdersFromTable(venueId: string, tableId: number): Observable<any> {
		return this._http.get<any>(
			`${environment.customersApiUrl}/livemenu/order/currentTableOrder?venue=${venueId}&table=${tableId}`
		)
	}

	/**
	 * List orders within a table (with status) group by user
	 * @param venueId
	 * @param tableId
	 * @returns request from server with the order's status
	 */
	public getOrdersFromTableGroupByUser(venueId: string, tableId: number): Observable<any> {
		return this._http.get<any>(
			`${environment.customersApiUrl}/livemenu/order/currentTableOrderGroupByUser?venue=${venueId}&table=${tableId}`
		)
	}

	/**
	 * Place an order in a table
	 * @param venueId
	 * @param tableId
	 * @param productUser
	 * @param order
	 * @returns figure it out when the request is done
	 */
	public postNewOrder(
		venueId: string,
		tableId: number,
		productUser: IPartialProductsUsers,
		order: Cart,
		waiter?: IWaiter,
		isDirectOrder?: boolean,
		payment?: any
	) {
		const procOrder = this.prepareShoppingCart(order.items)

		/* Todo: tableId as string ex: "2A", "varanda", "balcão" */
		const bodyRequest = {
			productUser,
			venue: venueId,
			table: +tableId,
			shoppingCart: { items: procOrder },
			waiter,
			payment,
		}

		return this._http.post<any>(`${environment.customersApiUrl}/livemenu/order`, bodyRequest).pipe(
			map(order => {
				this.setTableOrder(order)

				this.store.dispatch(tableOrderPerUserLoad({ venueId, tableId: +tableId }))

				if (!isDirectOrder) {
					this.store.dispatch({ type: cartActionTypes.clearCart })
				}

				return order
			}),
			catchError(err => {
				if (err.status === 500) {
					// TODO: usar isso aqui pra trackear pedidos com erros
					console.warn('Houve um problema ao tentar criar a comanda, tente novamente mais tarde', err)
				}
				return throwError(err)
			})
		)
	}

	public setTableOrder(obj: LiveMenuOrder) {
		this.store.dispatch(tableOrderUpdate({ tableOrder: obj }))
	}

	prepareShoppingCart(order: OrderItem[]): LiveMenuShoppingCartMenuItem[] {
		return order?.map(({ item, quantity }: OrderItem) => ({
			menuItem: item._id,
			amount: quantity,
			obs: item.obs,
			selectedOptions: item.selectedOptions,
		}))
	}

	setCurrentTable(table: number) {
		this.store.dispatch(tableOrderSetCurrentTable({ table: table }))
	}

	calcMyOrdersValueAccepted() {
		if (this.tableOrder?.orders?.length) {
			const arrPricesInCents = this.tableOrder.orders
				.filter(
					order =>
						order.productUser?._id === this.authService.getCurrentUserId() &&
						(order.status === 'Accepted' || order.status === 'Paid')
				)
				?.map(order => order.shoppingCart.promoPriceInCents || order.shoppingCart.priceInCents)

			let totalValueAccepted = 0

			for (const price of arrPricesInCents) {
				totalValueAccepted += price + Math.round((price * this.tableOrder.serviceChargeInPercent) / 100)
			}

			return totalValueAccepted
		} else {
			return 0
		}
	}

	calcMyOrdersValuePaid() {
		return (
			this.tableOrder.paymentHistory
				?.filter(history => history.productUser?._id === this.authService.getCurrentUserId())
				?.map(history => history.value)
				?.reduce((a, b) => a + b, 0) || 0
		)
	}

	calcTotalOrdersValuePaid() {
		return this.tableOrder.paymentHistory?.map(history => history.value)?.reduce((a, b) => a + b, 0) || 0
	}

	updateCartItem(cartItem, orderId, tableOrderId) {
		const cartItemAdapted = {
			cartItemId: cartItem._id,
			amount: cartItem.quantity,
			menuItem: cartItem.item._id,
			obs: cartItem.item.obs,
			selectedOptions: cartItem.item.selectedOptions,
		}

		const order = {
			orderId: orderId,
			tableOrderId: tableOrderId,
			shoppingCart: {
				items: [cartItemAdapted],
			},
		}

		return this._http.put<any>(`${environment.customersApiUrl}/livemenu/order/cartItem`, order)
	}

	writeOffIndividualOrder(productUserId: string, tableOrderId: string) {
		const payload = {
			productUserId,
			tableOrderId,
		}

		return this._http.put<any>(
			`${environment.customersApiUrl}/livemenu/order/writeOffIndividualOrder`,
			payload
		)
	}

	confirmDeliveryOrder(productUserId: string, orderId: string, venueId: string) {
		const payload = {
			productUserId,
			orderId,
			venueId,
		}

		return this._http.put<any>(`${environment.customersApiUrl}/livemenu/order/confirmDeliveryOrder`, payload)
	}

	orderAgain(orderId?: string, menuItemId?: string) {
		return this._http.post<any>(`${environment.customersApiUrl}/livemenu/order/orderAgain`, {
			orderId,
			menuItemId,
		})
	}

	getCurrentTableOrder(venueId: string, table: number): Observable<LiveMenuTableOrder> {
		return this._http
			.get<any>(
				`${environment.customersApiUrl}/livemenu/order/currentTableOrder?venue=${venueId}&table=${table}`
			)
			.pipe(
				map((response: any) => {
					return response
				}),
				catchError(err => {
					console.error('err', err)
					return of(err)
				})
			)
	}
}
