import { IPartialProductsUsers } from './IProductsUsers'
import { LiveMenuOrder } from './livemenu-order.interface'

export interface PaymentHistory {
	date: Date
	productUser: IPartialProductsUsers
	value: number
}

export interface LiveMenuTableOrder {
	_id: string
	productUser: IPartialProductsUsers
	venue: string
	table: number
	paymentHistory: PaymentHistory[]
	orders: LiveMenuOrder[]
	isTableOrder: boolean
	serviceChargeInPercent: number
	remainingOrdersPrice: number
	ordersPriceSum: number
	status: string
	logs?: any[]
}

export interface TableOrderState {
	loading?: boolean
	table: LiveMenuTableOrder
	updatedAt: Date
}

export const initialState: TableOrderState = {
	loading: true,
	table: {
		_id: '',
		productUser: {
			_id: null,
			name: '',
		},
		venue: '',
		table: null,
		paymentHistory: [],
		orders: [],
		isTableOrder: true,
		serviceChargeInPercent: 0,
		remainingOrdersPrice: 0,
		ordersPriceSum: 0,
		status: '',
	},
	updatedAt: new Date(),
}
