import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { of } from 'rxjs'
import { map, catchError, exhaustMap } from 'rxjs/operators'
import { MenuService } from '../../services/menu.service'
import { menuLoad, menuSuccess, menuError } from './../actions/menu.action'

@Injectable()
export class MenuEffects {
	constructor(private actions$: Actions, private menuService: MenuService) {}

	loadMenu$ = createEffect(() =>
		this.actions$.pipe(
			ofType(menuLoad),
			exhaustMap(action =>
				this.menuService.getMenuFromVenue(action.id, 'Dine-in').pipe(
					map(menu => {
						return menuSuccess({
							payload: {
								updatedAt: new Date(),
								loading: false,
								menus: menu,
								error: null,
							},
						})
					}),
					catchError(err =>
						of(
							menuError({
								payload: {
									error: err,
								},
							})
						)
					)
				)
			)
		)
	)
}
