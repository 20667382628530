import { BehaviorSubject, Observable } from 'rxjs'

import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { environment } from '../../environments/environment'

import { ICallWaiter, ICallWaiterGetInfo, IPutHandDown } from '../interfaces/call-waiter.interface'

@Injectable({ providedIn: 'root' })
export class CallWaiterService {
	private callWaiterCooldown: Date | null = null

	private cooldownSubject: BehaviorSubject<any>
	public cooldownObservable: Observable<any>

	constructor(private http: HttpClient) {
		this.cooldownSubject = new BehaviorSubject<Date | null>(this.callWaiterCooldown)
		this.cooldownObservable = this.cooldownSubject.asObservable()
	}

	callWaiter(payload: ICallWaiter): Observable<Response> {
		return this.http.post<any>(`${environment.customersApiUrl}/call-waiter/call/`, payload)
	}

	putHandDown(payload: IPutHandDown): Observable<Response> {
		return this.http.post<any>(`${environment.customersApiUrl}/call-waiter/put-hand-down/user/`, payload)
	}

	getCallWaiterInfo(payload: ICallWaiterGetInfo) {
		return this.http.get<any>(
			`${environment.customersApiUrl}/call-waiter/get-info-user/${payload.productUserId}/${payload.tableOrderId}/`
		)
	}

	setCallWaiterCooldown(seconds: number): void {
		const now = new Date()
		this.callWaiterCooldown = new Date(now.setSeconds(now.getSeconds() + seconds))

		setTimeout(() => {
			this.callWaiterCooldown = null
			this.cooldownSubject.next(this.callWaiterCooldown)
		}, seconds * 1000)

		this.cooldownSubject.next(this.callWaiterCooldown)
	}
}
