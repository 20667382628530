import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

import { VenueService } from './venue.service'

const languages = {
	pt: 'pt-br',
	de: 'de',
	en: 'en',
	es: 'es',
	fr: 'fr',
	it: 'it',
}

@Injectable({
	providedIn: 'root',
})
export class i18nService {
	private language = new BehaviorSubject<string>('pt-br')
	_language = this.language

	constructor(private venueService: VenueService) {
		const navigatorLanguage = navigator.language.toLowerCase().split('-')[0]
		const storageLanguage = sessionStorage.getItem('lang')

		if (storageLanguage) {
			this.changeLanguage(storageLanguage)
		} else {
			this.changeLanguage(languages[navigatorLanguage] || this.venueService.getDefaultLanguage())
		}
	}

	public changeLanguage(language: string) {
		sessionStorage.setItem('lang', language)
		this.language.next(language)
	}

	public translateProperty(object: { property: string }): string {
		const language: string = this.language.value === 'pt-br' ? 'pt' : this.language.value

		return object ? object[language] || object['pt'] : ''
	}
}
