import { Option } from '../services/menu-item.service'

export interface MenuItemPreSend {
	_id: string
	quantity: number
	item: CartItem
}

export interface Cart {
	_id?: string
	createdAt?: Date
	items: OrderItem[]
}

export interface CartItem {
	_id?: string
	avatarUrl?: string
	descript?: {
		en: string
		pt: string
	}
	highLightFeatures?: any[]
	menuItem?: string
	menus?: string[]
	name?: string
	obs?: string
	options?: any[]
	owner?: string
	price: number
	promoPrice?: number
	selectedOptions?: Array<Complement>
	slug?: string
	subitems?: []
	status?: string
	type?: string
	updated_at?: Date
	venues?: string[]
}

export interface Complement {
	selectedOption: string
	name?: string
	volume?: string
	wineVolume?: WineVolumeName
	amount: number
	price: number
	promoPrice: number
	promoPriceEnabled: boolean
	option: Option
	disabled: boolean
}

interface WineVolumeName {
	pt?: string
	en?: string
	es?: string
	fr?: string
}

export interface OrderItem {
	_id?: string
	item: CartItem
	quantity: number
	status?: string
}

export interface CartState {
	_id: string
	updatedAt: Date
	items: OrderItem[]
	loading: Boolean
	loaded: Boolean
}

export const initialCartState: CartState = {
	_id: (+new Date()).toString(36),
	updatedAt: new Date(),
	items: [],
	loading: false,
	loaded: true,
}

export class LiveMenuShoppingCartMenuItem {
	menuItem: string
	amount: number
	obs: string
	selectedOptions: Array<LiveMenuShoppingCartMenuItemSelectedOption>
}

export class LiveMenuShoppingCartMenuItemSelectedOption {
	selectedOption: string
	amount: number
}
