import { Injectable } from '@angular/core'

import { i18nService } from './i18n.service'

import { ScheduledDeactivation } from '../interfaces/scheduledDeactivation.interface'
import { AllowedPaymentMethods } from '../interfaces/live-menu-settings-enums.interface'

@Injectable({
	providedIn: 'root',
})
export class UtilsService {
	language = 'pt'

	paymentsLabel = {
		pt: {
			credit_card: 'Cartão de Crédito',
			debit_card: 'Cartão de Débito',
			pix: 'Pix',
		},
		en: {
			credit_card: 'Credit Card',
			debit_card: 'Debit Card',
			pix: 'Pix',
		},
		es: {
			credit_card: 'Tarjeta de Crédito',
			debit_card: 'Tarjeta de Débit',
			pix: 'Pix',
		},
		fr: {
			credit_card: 'Carte de Crédit',
			debit_card: 'Carte de Débit',
			pix: 'Pix',
		},
	}

	constructor(private i18nService: i18nService) {
		this.i18nService._language.subscribe(language => {
			this.language = language.split('-')[0]
		})
	}

	adaptAllowedPaymentMethods(allowedPaymentMethods: AllowedPaymentMethods[]) {
		return allowedPaymentMethods.map(paymentMethod => {
			return {
				id: paymentMethod.toLocaleLowerCase(),
				label: this.adaptAllowedPaymentMethodsLabel(paymentMethod),
			}
		})
	}

	adaptAllowedPaymentMethodsLabel(paymentMethod: AllowedPaymentMethods): string {
		switch (paymentMethod) {
			case AllowedPaymentMethods.CREDIT_CARD:
				return this.paymentsLabel[this.language].credit_card

			case AllowedPaymentMethods.DEBIT_CARD:
				return this.paymentsLabel[this.language].debit_card

			case AllowedPaymentMethods.PIX:
				return this.paymentsLabel[this.language].pix
		}
	}

	dateFormatYYYYMMDDHHmmss(date: Date) {
		const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
		const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
		const year = date.getFullYear()

		const hour = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
		const minute = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
		const second = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds()

		return `${year}-${month}-${day} ${hour}:${minute}:${second}`
	}

	isScheduleDeactivated(scheduledDeactivation?: ScheduledDeactivation[], readOnly?: boolean): boolean {
		if (!scheduledDeactivation || scheduledDeactivation.length === 0) {
			return false
		}

		const now = new Date()

		const weekDay = now.getDay()

		const scheduledDays = scheduledDeactivation.filter(schedule => {
			if (readOnly) {
				return schedule.enabled && schedule.readOnly && schedule.weekDays.find(day => day === weekDay)
			}

			return schedule.enabled && !schedule.readOnly && schedule.weekDays.find(day => day === weekDay)
		})

		if (scheduledDays.length === 0) {
			return false
		}

		const arrHasScheduleDeactivation = []

		for (const schedule of scheduledDays) {
			let startDate = new Date()
			startDate.setHours(+schedule.startTime.split(":")[0])
			startDate.setMinutes(+schedule.startTime.split(":")[1])
			startDate.setSeconds(+schedule.startTime.split(":")[2])

			let endDate = new Date()
			endDate.setHours(+schedule.endTime.split(":")[0])
			endDate.setMinutes(+schedule.endTime.split(":")[1])
			endDate.setSeconds(+schedule.endTime.split(":")[2])

			arrHasScheduleDeactivation.push(startDate < now && endDate > now)
		}

		return arrHasScheduleDeactivation.some(hasDeactived => hasDeactived)
	}
}
