import { Injectable } from '@angular/core'
import { createAction, props } from '@ngrx/store'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { EMPTY, of } from 'rxjs'
import { map, catchError, exhaustMap } from 'rxjs/operators'
import { ThemeService } from '../../services/theme.service'
import { themeLoad, themeSuccess, themeError } from './../actions/theme.action'

@Injectable()
export class ThemeEffects {
	constructor(private actions$: Actions, private themeService: ThemeService) {}

	loadTheme$ = createEffect(() =>
		this.actions$.pipe(
			ofType(themeLoad),
			exhaustMap(action =>
				this.themeService.getThemeFromVenue(action.id).pipe(
					map(theme => themeSuccess({ payload: { loading: false, ...theme } })),
					catchError(error => of(themeError(error)))
				)
			)
		)
	)
}
