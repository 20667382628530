import { localStorageSync } from 'ngrx-store-localstorage'
import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store'

import { CartState } from '../../interfaces/cart.interface'
import { TableOrderState } from '../../interfaces/livemenu-table-order.interface'
import { TableOrderPerUserState } from '../../interfaces/IOrdersPerUser.interface'

import { CartReducer } from './cart.reducers'
import { MenuReducer } from './menu.reducers'
import { VenueState } from './venue.reducers'
import { VenueReducer } from './venue.reducers'
import { TableOrderReducer } from './tableOrder.reducers'
import { UserReducer, UserState } from './users.reducers'
import { ThemeReducer, ThemeState } from './theme.reducers'
import { TableOrderPerUserReducer } from './tableOrderPerUser.reducers'
import { LiveMenuSettingsReducer, LiveMenuState } from './live-menu-settings.reducers'

import { MenuEffects } from '../effects/menu.effects'
import { UserEffects } from '../effects/user.effects'
import { ThemeEffects } from '../effects/theme.effects'
import { VenueEffects } from '../effects/venue.effects'
import { TableOrderEffects } from '../effects/tableOrder.effects'
import { LiveMenuSettingsEffects } from '../effects/live-menu-settings.effects'
import { TableOrderPerUserEffects } from '../effects/tableOrderPerUser.effects'

import { MenuState } from '../../services/menu.service'
import { VenueService } from '../../services/venue.service'

const INIT_ACTION = '@ngrx/store/init'
const UPDATE_ACTION = '@ngrx/store/update-reducers'

const mergeReducer = (state, rehydratedState, action) => {
	if ((action.type === INIT_ACTION || action.type === UPDATE_ACTION) && rehydratedState) {
		state = {
			...state,
			...rehydratedState,
		}
	}
	return state
}

export const rootEffects = [
	VenueEffects,
	ThemeEffects,
	MenuEffects,
	UserEffects,
	TableOrderEffects,
	LiveMenuSettingsEffects,
	TableOrderPerUserEffects,
]

export const reducers: ActionReducerMap<any> = {
	cart: CartReducer,
	menu: MenuReducer,
	user: UserReducer,
	theme: ThemeReducer,
	venue: VenueReducer,
	tableOrder: TableOrderReducer,
	liveMenu: LiveMenuSettingsReducer,
	tableOrderPerUser: TableOrderPerUserReducer,
}

export interface AppState {
	cart: CartState
	menu: MenuState
	user: UserState
	theme: ThemeState
	venue: VenueState
	liveMenu: LiveMenuState
	tableOrder: TableOrderState
	tableOrderPerUser: Array<TableOrderPerUserState>
}

export const metaReducers: Array<MetaReducer<any, any>> = [SyncReducer]

export function SyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
	const storedVenueId = localStorage.getItem('venueId')
	const reduxVenueId = JSON.parse(localStorage.getItem('venue'))?._id
	const rehydrateCondition = storedVenueId == reduxVenueId

	const liveMenuProReducers = VenueService.prototype.hasLiveMenuPro()
		? [
				'cart',
				{
					tableOrder: {
						encrypt: (state: string) => btoa(encodeURIComponent(state)),
						decrypt: (state: string) => decodeURIComponent(atob(state)),
					},
				},
				{
					tableOrderPerUser: {
						encrypt: (state: string) => btoa(encodeURIComponent(state)),
						decrypt: (state: string) => decodeURIComponent(atob(state)),
					},
				},
				{
					user: {
						encrypt: (state: string) => btoa(encodeURIComponent(state)),
						decrypt: (state: string) => decodeURIComponent(atob(state)),
					},
				},
		  ]
		: []

	return localStorageSync({
		keys: [
			'venue',
			'theme',
			'liveMenu',
			//'menu',
			...liveMenuProReducers,
		],
		rehydrate: rehydrateCondition,
		mergeReducer,
	})(reducer)
}
