import {
	LiveMenu,

} from '../../services/live-menu-settings.service'

import {
	ByPassType,
	PaymentJourney,
	RegisterCVVMode,
	PointOfSalesMode,
	PaymentFormsMode,
	AccommodationType,
	PaymentAntifraudMode,
	DeliveryConfirmationMode,
	EstimatedOrderStatusTimeMode,
} from '../../interfaces/live-menu-settings-enums.interface'

import { liveMenuActionTypes } from '../actions/live-menu-settings.action'

export interface LiveMenuState {
	readonly liveMenu: LiveMenu
}

const initialState: LiveMenu = {
	loading: true,
	_id: '',
	venue: '',
	pro: false,
	settings: {
		callWaiter: false,
		driveThru: false,
		byPassRules: {
			type: ByPassType.DISABLED,
			custom: {
				menus: [],
				items: [],
			},
		},
		pdvIntegration: false,
		paymentRules: {
			paymentJourney: PaymentJourney.POST_PAID,
			pointOfSalesMode: PointOfSalesMode.DEFAULT,
			allowedPaymentMethods: [],
		},
	},
	optionals: {
		payment: {
			preAuth: null,
			creditCard: {
				registerCVVMode: RegisterCVVMode.STATIC_REQUIRE_ALWAYS,
				antiFraudMode: PaymentAntifraudMode.DEFAULT,
				billingForms: {
					address: PaymentFormsMode.REQUIRED,
				},
			},
		},
		order: {
			estimatedStatusTimeMode: EstimatedOrderStatusTimeMode.NOT_ESTIMATE,
			deliveryConfirmationMode: DeliveryConfirmationMode.AUTO,
		},
	},
	style: {
		landing: null,
		labeling: {
			accommodation: AccommodationType.TABLE
		},
	}
}

export function LiveMenuSettingsReducer(state: LiveMenu = initialState, action): LiveMenu {
	switch (action.type) {
		case liveMenuActionTypes.liveMenuSuccess:
			return {
				...state,
				...action.payload,
			}

		case liveMenuActionTypes.liveMenuError:
			return state

		case liveMenuActionTypes.liveMenuClear:
			return initialState

		default:
			return state
	}
}
