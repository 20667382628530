// import the interface
import { Theme } from '../../services/theme.service'
import { themeActionTypes } from '../actions/theme.action'

export interface ThemeState {
	readonly theme: Theme
}

const initialState: Theme = {
	loading: true,
	venue: null,
	color: {
		main: null,
		background: null,
		accentBackground: null,
		border: null,
	},
	logo: {
		vertical: null,
		horizontal: null,
	},
	inspirationalImage: {
		small: null,
		monochromatic: null,
		large: null,
	},
	banners: {
		small: [],
		large: [],
		list: [],
	},
	typeImageSpotlight: null,
	menuLayout: null,
	updated_at: null,
}

export function ThemeReducer(state: Theme = initialState, action: any) {
	switch (action.type) {
		case themeActionTypes.themeSuccess:
			return {
				...state,
				...action.payload,
			}
		case themeActionTypes.themeError:
			return state
		case themeActionTypes.themeClear:
			return initialState
		default:
			return state
	}
}
