import { Injectable } from '@angular/core'

import { VenueService } from './venue.service'

import { environment } from '../../environments/environment'

@Injectable({
	providedIn: 'root',
})
export class RedirectToNewLiveMenuService {
	constructor(private venueService: VenueService) {}

	checkAndRedirect() {
		const gurumeVenueIds = [
			'56c777520896b3cd13c603dc',
			'5c543af89fb620aa38a3f2dd',
			'5d25f2770a4ababa6db45949',
			'5d7297e70a4ababa6d27c034',
			'5da612ab0a4ababa6ddee6fc',
			'643da1c636739a00555c5d1d',
			'64fb87bf3188ad005b76e9bd',
			'6525719b85a1bc005b48aa94',
			'65d7490fda37e20071b3bfcf',
		]
		const jojoRamenVenueIds = [
			'65c3a124b4cea6006ab61f30',
			'65c291c3b4cea6006ab5865f',
			'65c13285b4cea6006ab4a403',
		]
		const nolita = ['5aba949bfa271c9ee3c3ea28']
		const tocaDaTraira = [
			'5b7c3ec4fa271c9ee3c4ecb2',
			'5b7c3ec0fa271c9ee3c4ecae',
			'5bbe0db5fa271c9ee3c573dc',
			'5b7c3ebdfa271c9ee3c4ecac',
			'5bb4c9bffa271c9ee3c567b4',
		]
		/* const barDoZecaPagodinho = [
			'5f32db362d81be01265dce36',
			'615a58ce87c266002005987c',
			'6377c7915da840004aae0ff0',
			'65eca96ae2227b00692a272a',
			'6601dd468d0d04007195cc69',
		] */
		const huto = [
			/* '5dadb42e0a4ababa6d7b4905',
			'60a27ca6cf5fcf0035b84bf3',
			'60a29b96002a6c00359fbd4b',
			'60a67354e4f9200b74a4f96b',
			'6499dfe1a514d70055ced667', */
		]
		const jappaDaQuitanda = [
			'61a661ecd999480021ea4f92',
			'61a67792657b3d0012fc26fe',
			'63a48c51e7a6da0052821621',
			'638f55a1611c71004ae06cd0',
			'65672c8b5dc698005aaa2efa',
		]
		const paris6 = [
			'65033d4c9f4693005586135c',
			'660afa5814033c006c69d095',
			'660afaa072931900711cf005',
			'660afb1c9f7b7e0072c743c8',
			'660afb279f7b7e0072c743ce',
			'660afb3357b01e0071622314',
			'661693c5ac711d007146ad47',
			'66169666ac711d007146ae4a',
		]
		const cabanaBurger = ['66b3beb81c9eeb89ca9bc02d', '66b3bed51c9eeb89ca9bc44a']
		const jam = ['6454f3ac93c09d005616c873', '645a88527ac866005475cf48']
		const macaw = ['666afb52d91d31d1820e3f52', '666c5b1d6166183b61ee88e8']
		const ninoCucinaVino = ['5b0563f4fa271c9ee3c47487']
		const daMarinoIpanema = ['63b429c4322cc500528437e3']
		const ninettoRioDesign = ['663b9dfb8d69d50055987705']
		const giuliettaCarni = ['5ca39f5322db8f849ea3b8c2']
		const oliveGarden = [
			'5e583824e130c46f6b2746f7',
			'5eda5d935b6cc0319c382d69',
			'5eda743a5b6cc0319c38c81b',
			'5eda7e055b6cc0319c39c61c',
			'5edfeff2aab67728ba26e1fc',
			'6012c68e117fb85dccc1745d',
		]
		const yusha = ['60c8ecb85f041f4364d52704']
		const _1900 = [
			'5b085402fa271c9ee3c47671',
			'5b0c0e35fa271c9ee3c47751',
			'5b0c12b6fa271c9ee3c47841',
			'5b0c23f5fa271c9ee3c47981',
			'5b0c52c6fa271c9ee3c47d0c',
			'5b0c64b3fa271c9ee3c47da7',
			'5b0d82fffa271c9ee3c481ae',
			'61ae1b8298e46a002c0e4da3',
		]
		const siSenor = [
			'621641579080f300118eec98',
			'6216444eeed8b20012909790',
			'6216446f8c5bd400111c9202',
			'6319e7094b00e30012388a0a',
			'64dcd6e89d74cf005413aeb1',
			'64dcdc93176e7600551d37ce',
			'64dcde37257f1100552ce6e1',
			'64dce27c176e7600551d3864',
			'64dce54e627a68004f57fd5a',
			'64dceb202276a40052b78431',
			'64dcedfd0a54280054fbc2c2',
			'64dcf0b4257f1100552ce94f',
			'64dcf3409d74cf005413b1e1',
			'64dd073504a6d40051b5ccec',
			'64dd0b669d74cf005413b4a0',
			'64dd0d33176e7600551d3e4f',
			'64dd152e257f1100552cee02',
			'64dd16bba93538004ff10b94',
		]
		const gulaBar = ['64676fa8d1f2db0055fc38cf', '66a3d76c4fe4c7e0046c9d9d']
		const kiichi = ['5ae38ccafa271c9ee3c45703', '5d701fcc0a4ababa6d080f54', '5e9483ede130c46f6bc96b09']
		const ludwig = ['5c7431eb9fb620aa38a4384e']
		const badalado = [
			'6349b905f6cdac004a482478',
			'63727b82738ee3004a95a9fe',
			'648a0ef2d7f12d004f1930b8',
			'64aff34c5cfbc400550da83f',
			'64b94977c4ead2004e169c73',
			'652570aa85a1bc005b48a8c9',
		]
		const macaxeira = [
			'61fc2c728966f0001265dac3',
			'61fd1e048966f00012660a66',
			'631f7824eb6971002092dd43',
			'632090983130e30011e7078d',
			'632092d04b00e300123a1595',
			'6320c0a1eb69710020932b52',
			'6377e4c4f73d62004a69d971',
			'63a35a275def5d00522db078',
			'641d9f14131832005c62649a',
			'64808a43113b1c004e77952d',
			'649c3553e13ae000553be328',
			'64de18512276a40052b798f3',
			'653032b04790f4005b31e1f0',
			'653fe39bfbc78b005a79ab27',
		]
		const salGastronomia = ['5e2f133c0a4ababa6db87f82', '5ff706ea53ea855a788f185b']
		// const bottegaDelMare = ['65c66a51b4cea6006ab87041']
		// const micale = ['63ab420ec2b0270052dd07c7']
		const pipasTerroir = ['64d3926cf4cca00054b86ae1']
		const laMaddalena = ['6509a541cb79c6005343fdc6', '6509a739b532300055dad32d']
		const donnaMargherita = ['65c65202b4cea6006ab85325', '65ce1316b4cea6006abea895']
		// const tasteIT = ['5cae299222db8f849ea3c1ab']
		// const lourdesSquare = ['644a9ad5f8307e0056bff0f5', '629fa3b9baad510011d0abe0']
		// const outbackVenueIds = ['66675f0a6bd83c4418d2c5d8']
		const scadaCafe = [
			'63875e2b0795e7004a0fe30a',
			'638df753e424ed004a978d3b',
			'638dfe3ed8d40a004a427f9b',
			'638e04580795e7004a11124e',
			'638e087a0795e7004a1112c6',
			'638e0cab10eead004a44e767',
			'649c47175bbf100055535b1e',
			'649c472db04c4c00553b6b75',
		]
		const giardinoTrattoriaVogue = ['6679c96f332beda5c7d788ae']
		const curadoria = ['62d9484a505d230020bae5ed']
		const minimok = ['56c777b90896b3cd13c6076c', '56c777ba0896b3cd13c6077c']
		const dryMomentsDrinks = ['5fbd193eec61af0035e369a1', '5fb42191e57bf200357d2e88']
		const botegare = ['6570dc7a20b293007222f78b']
		const nemoSushi = ['664ca39a8ea2ce06a39edd51']
		const ambaiHallFestas = ['6358369da90f29004e929e54']

		const allowedVenueIds = [
			...gurumeVenueIds,
			...jojoRamenVenueIds,
			...nolita,
			...tocaDaTraira,
			// ...barDoZecaPagodinho,
			...huto,
			...jappaDaQuitanda,
			// ...paris6,
			...cabanaBurger,
			...jam,
			...macaw,
			...ninoCucinaVino,
			...daMarinoIpanema,
			...ninettoRioDesign,
			...giuliettaCarni,
			...oliveGarden,
			...yusha,
			..._1900,
			...siSenor,
			...gulaBar,
			...kiichi,
			...ludwig,
			...badalado,
			...macaxeira,
			...salGastronomia,
			// ...bottegaDelMare,
			// ...micale,
			...pipasTerroir,
			...laMaddalena,
			// ...donnaMargherita,
			// ...tasteIT,
			// ...lourdesSquare,
			...scadaCafe,
			...giardinoTrattoriaVogue,
			...curadoria,
			...minimok,
			// ...dryMomentsDrinks,
			...botegare,
			...nemoSushi,
			...ambaiHallFestas,
		]
		const currentVenueId = this.venueService.getVenueId

		const isNotPrintPage = !window.location.href.includes('print')

		if (
			environment.production &&
			currentVenueId &&
			allowedVenueIds.includes(currentVenueId) &&
			isNotPrintPage
		) {
			window.location.href = `${environment.newLiveMenu}/pt/menu/${currentVenueId}`
		}
	}
}
