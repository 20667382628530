import { Observable, Subject, throwError } from 'rxjs'
import { catchError, filter, takeUntil } from 'rxjs/operators'

import { Actions } from '@ngrx/effects'

import { Store } from '@ngrx/store'
import { TranslateService } from '@ngx-translate/core'

import { HttpParams } from '@angular/common/http'
import { DOCUMENT, Location } from '@angular/common'
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router'
import { Component, HostListener, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core'

import { UrlService } from './services/url.service'
import { AppService } from './services/app.service'
import { i18nService } from './services/i18n.service'
import { AuthService } from './services/auth.service'
import { ModalService } from './services/modal.service'
import { VenueService } from './services/venue.service'
import { LoaderService } from './services/loader.service'
import { TableOrderService } from './services/tableOrder.service'
import { CallWaiterService } from './services/call-waiter.service'
import { PushNotificationService } from './services/push-notification.service'
import { TableOrderSocketService } from './services/tableOrder.socket.service'

import { environment } from '../environments/environment'

import { AppState } from './store/reducers'
import { UserState } from './store/reducers/users.reducers'

import { LiveMenuOrder } from './interfaces/livemenu-order.interface'
import { CallWaiterPermission, IPutHandDownResponse } from './interfaces/call-waiter.interface'

import { userSuccess } from './store/actions/user.action'
import { tableOrderLoad, tableOrderUpdate } from './store/actions/tableOrder.action'
import { tableOrderPerUserLoad, tableOrderPerUserUpdate } from './store/actions/tableOrderPerUser.action'
import { LiveMenuTableOrder } from './interfaces/livemenu-table-order.interface'

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
	public ngDestroyed$ = new Subject()

	currentUser: UserState
	currentTable: number
	venueId: string = this.venueService.getVenueId
	routeParams: Params

	prev: LiveMenuOrder[] = []
	isLiveMenuPro = this.venueService.hasLiveMenuPro()

	previousUrl: string = null
	currentUrl: string = null

	liveMenuStarterClass = 'live-menu-starter'
	liveMenuProClass = 'live-menu-pro'

	ws = this.tableOrderSocketService
	wsInitialized = false

	language = 'pt'
	snackbarMessage = this.appService.snackbarMessage

	isContingency = false

	reasonOrderCanceledRefused = ''

	@HostListener('window:message', ['$event'])
	onMessage(event) {
		this.messageListener(event)
	}

	htmlEl = this.document.documentElement

	constructor(
		@Inject(DOCUMENT) private document: Document,
		private router: Router,
		public updates$: Actions,
		private location: Location,
		private renderer: Renderer2,
		private route: ActivatedRoute,
		private appService: AppService,
		private urlService: UrlService,
		private store: Store<AppState>,
		private i18nService: i18nService,
		private authService: AuthService,
		private modalService: ModalService,
		private venueService: VenueService,
		private loaderService: LoaderService,
		private translateService: TranslateService,
		private tableOrderService: TableOrderService,
		private callWaiterService: CallWaiterService,
		private pushNotifications: PushNotificationService,
		private tableOrderSocketService: TableOrderSocketService
	) {
		this.isContingency = this.document.location.href.includes('/pdf/')

		this.i18nService._language.pipe(takeUntil(this.ngDestroyed$)).subscribe(language => {
			this.translateService.use(language)
			this.language = language.split('-')[0]
		})

		this.router.events
			.pipe(
				filter(event => event instanceof NavigationEnd),
				takeUntil(this.ngDestroyed$)
			)
			.subscribe((event: NavigationEnd) => {
				this.previousUrl = this.currentUrl
				this.currentUrl = event.url
				this.urlService.setPreviousUrl(this.previousUrl)
			})

		if (this.isLiveMenuPro) {
			this.store
				.select('user')
				.pipe(takeUntil(this.ngDestroyed$))
				.subscribe(userState => {
					this.currentUser = userState

					if (this.currentUser?.isAuthenticated && this.currentTable && !this.wsInitialized) {
						this.startOrdersWebsocket(this.currentUser?.user.accessToken, this.currentTable)
					}
				})

			this.renderer.removeClass(this.htmlEl, this.liveMenuStarterClass)
			this.renderer.addClass(this.htmlEl, this.liveMenuProClass)
		} else {
			this.ws.closeSocket()

			this.renderer.removeClass(this.htmlEl, this.liveMenuProClass)
			this.renderer.addClass(this.htmlEl, this.liveMenuStarterClass)
		}
	}

	ngOnInit(): void {
		this.loaderService.start()

		if (this.isLiveMenuPro) {
			this.route.queryParams.subscribe(params => {
				this.routeParams = params
				if (!params.table) {
					this.store
						.select('tableOrder')
						.pipe(takeUntil(this.ngDestroyed$))
						.subscribe(tableOrderState => {
							this.currentTable = tableOrderState.table?.table
						})
				} else {
					this.tableOrderService.setCurrentTable(+params.table)
					this.currentTable = +params.table
				}
			})

			this.router.events.subscribe(ev => {
				if (ev instanceof NavigationEnd) {
					let { table, ...qs } = this.routeParams
					let params = new HttpParams().appendAll(qs)

					this.location.replaceState(location.pathname, params.toString(), history.state)
				}
			})

			if (window.self !== window.top) {
				window.parent.postMessage({ waiterIframeLoaded: true }, environment.waiterUrl)
			}
		} else {
			localStorage.removeItem('cart')
			localStorage.removeItem('user')
			localStorage.removeItem('tableOrder')
			localStorage.removeItem('tableOrderPerUser')
		}
	}

	ngOnDestroy(): void {
		this.ngDestroyed$.next()
	}

	startOrdersWebsocket(accessToken: string, table: number) {
		this.wsInitialized = this.ws.initSocket(accessToken, this.venueId, table)

		this.ws
			.onEvent('ON_PDV_EVENT')
			.pipe(
				takeUntil(this.ngDestroyed$),
				catchError((error): Observable<any> => {
					console.log('ERROR ON_PDV_EVENT', error)
					return throwError(error)
				})
			)
			.subscribe(response => {
				if (!response?.resolved) return this.openModal('modal-pdv-error')
				this.openModal('modal-pdv-error')
			})

		this.ws
			.onEvent('ON_STATUS_UPDATE')
			.pipe(takeUntil(this.ngDestroyed$))
			.subscribe((response: any) => {
				this.store.dispatch(tableOrderUpdate({ tableOrder: response.order }))

				this.store.dispatch(
					tableOrderPerUserUpdate({
						payload: {
							loading: false,
							ordersPerUser: response.tableOrderPerUser,
						},
					})
				)
			})

		this.ws
			.onEvent('ON_STATUS_TABLE_ORDER_UPDATE')
			.pipe(takeUntil(this.ngDestroyed$))
			.subscribe((response: any) => {
				if (response.action === 'close-table') {
					this.logout()
				} else if (response.order && response.order.productUser._id === this.currentUser.user.userId) {
					switch (response.action) {
						case 'cancel':
						case 'refuse':
						case 'cancel-item':
						case 'refuse-item':
							this.reasonOrderCanceledRefused = response.reason

							this.openModal('modal-order-canceled-refused')

							break
					}

					this.appService.createMessageFromWS(response.action)
					this.appService.activateSnackbar()

					this.pushNotifications.showNotification({
						title: this.appService.snackbarMessage.title,
						body: this.appService.snackbarMessage.body,
						icon: '../../assets/favicons/icon-192x192.png',
						data: {
							type: 'orderStatus',
						},
					})
				}

				this.store.dispatch(tableOrderUpdate({ tableOrder: response.tableOrder }))

				this.store.dispatch(
					tableOrderPerUserUpdate({
						payload: {
							loading: false,
							ordersPerUser: response.tableOrderPerUser,
						},
					})
				)

				// if (response.action === 'delivered') {
				// 	this.router.navigate([`/menu/${this.venueId}`])
				// }
			})

		this.ws
			.onEvent('ON_PUT_HAND_DOWN')
			.pipe(takeUntil(this.ngDestroyed$))
			.subscribe((response: IPutHandDownResponse) => {
				// IF WAITER PUT HAND DOWN, EVERYONE IN TABLE WILL HAVE ADD A COOLDOWN FOR 2 MINUTES
				// IT'LL TRIGGER COOLDOWN OBSERVABLE FROM CALL WAITER SERVICE
				if (response.tableNumber === this.currentTable) {
					this.callWaiterService.setCallWaiterCooldown(120)

					if (
						response.interactionType === 'payment' &&
						(response.productUser?._id === this.currentUser.user.userId || response.origin === 'waiter')
					) {
						localStorage.removeItem('payRequestValue')
						localStorage.removeItem('payRequestDate')

						this.appService.createMessageFromWS('pay-request-cancel')

						this.appService.activateSnackbar()

						if (this.currentUrl === '/pay/call-waiter') {
							setTimeout(() => {
								this.router.navigate([`/menu/${this.venueId}`])
							}, 2000)
						}
					}
				}
			})

		this.ws
			.onEvent('ON_CALL_WAITER_PERMISSION')
			.pipe(takeUntil(this.ngDestroyed$))
			.subscribe((response: CallWaiterPermission) => {
				let seconds = 0

				if (response.permission === 'deny') {
					seconds = response.seconds
				}

				if (!response.type || response.type !== 'payment') {
					// IF RESPONSE HAS TABLE NUMBER, EVERYONE IN TABLE'LL DE DENIED FOR N SECONDS (IF PERMISSION IS DENY)
					// OR RESET (IF PERMISSION IS ALLOW)
					if (response.tableNumber && response.tableNumber === this.currentTable) {
						this.callWaiterService.setCallWaiterCooldown(seconds)
					}
					// ELSE, ONLY THE ONES SELECTED BY WAITER
					else {
						const userDenied = response.arrProductUsersId.find(
							productUserId => productUserId === this.currentUser.user.userId
						)

						if (userDenied) {
							this.callWaiterService.setCallWaiterCooldown(seconds)
						}
					}
				}
			})

		this.ws
			.onEvent('ON_TABLE_ORDER_PAY')
			.pipe(takeUntil(this.ngDestroyed$))
			.subscribe((response: any) => {
				this.appService.createMessageFromWS(response.action)

				this.appService.activateSnackbar()

				this.store.dispatch(tableOrderUpdate({ tableOrder: response.tableOrder }))

				this.store.dispatch(
					tableOrderPerUserUpdate({
						payload: {
							loading: false,
							ordersPerUser: response.tableOrderPerUser,
						},
					})
				)
			})

		this.ws
			.onEvent('ON_TABLE_ORDER_PAY_CANCEL')
			.pipe(takeUntil(this.ngDestroyed$))
			.subscribe((response: any) => {
				this.appService.createMessageFromWS(response.action)

				this.appService.activateSnackbar()

				this.store.dispatch(tableOrderUpdate({ tableOrder: response.tableOrder }))

				this.store.dispatch(
					tableOrderPerUserUpdate({
						payload: {
							loading: false,
							ordersPerUser: response.tableOrderPerUser,
						},
					})
				)
			})

		this.ws
			.onEvent('ON_REMOVE_FROM_TABLE')
			.pipe(takeUntil(this.ngDestroyed$))
			.subscribe((response: { productUserId: string }) => {
				if (this.currentUser?.user?.userId && response.productUserId === this.currentUser.user.userId) {
					this.logout()
				}
			})
	}

	stopLoading() {
		this.loaderService.stop()
	}

	openModal(id: string) {
		this.modalService.open(id)
	}

	closeModal(id: string) {
		this.modalService.close(id)
	}

	closeModalLogout() {
		this.closeModal('app-modal-info')

		window.location.reload()

		this.router.navigate([`/menu/${this.venueService.getVenueId}`])
	}

	sendToLiveMenuQRCode() {
		this.closeModal('app-modal-menu-error')

		this.router.navigate([''])
	}

	messageListener(event: any) {
		if (event.data.waiterMode) {
			const postMessageData = event.data.data

			this.authService.logout()

			if (postMessageData.type === 'UPDATE_ORDER') {
				this.renderer.addClass(this.htmlEl, 'waiter-mode-update')
			} else {
				this.renderer.addClass(this.htmlEl, 'waiter-mode')
			}

			this.tableOrderService.setCurrentTable(postMessageData.tableNumber)

			this.loginByWaiter(postMessageData)
		}
	}

	loginByWaiter(postMessageData: any): void {
		const payload = {
			waiterOrder: {
				waiter: { ...postMessageData.waiter, ...{ isWaiterOrder: true } },
				orderData: postMessageData.orderData,
			},
			accessToken: postMessageData.waiter.accessToken,
			userId: postMessageData.productUser._id,
			name: postMessageData.productUser.name,
		}

		this.store.dispatch(userSuccess({ payload }))

		this.store.dispatch(tableOrderLoad({ venueId: this.venueService.getVenueId, tableId: this.currentTable }))
		this.store.dispatch(
			tableOrderPerUserLoad({ venueId: this.venueService.getVenueId, tableId: this.currentTable })
		)
	}

	logout() {
		this.authService.logout()

		this.openModal('app-modal-info')

		setTimeout(() => {
			this.closeModalLogout()

			window.location.reload()
			this.router.navigate([`/menu/${this.venueService.getVenueId}`])
		}, 5000)
	}
}
