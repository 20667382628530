import { createAction, props } from '@ngrx/store'
import { Theme } from '../../services/theme.service'

export enum themeActionTypes {
	themeLoad = '[Theme] Theme Load',
	themeSuccess = '[Theme] Theme Load Success',
	themeError = '[Theme] Theme Load Error',
	themeClear = '[Theme] Theme Clear',
}

export const themeLoad = createAction(themeActionTypes.themeLoad, props<{ id: string }>())
export const themeSuccess = createAction(themeActionTypes.themeSuccess, props<{ payload: Theme }>())
export const themeError = createAction(themeActionTypes.themeError, props<{ error: any }>())
export const themeClear = createAction(themeActionTypes.themeClear)
