import { Injectable } from '@angular/core'
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http'

import { Observable } from 'rxjs'

import { AuthService } from '../services/auth.service'

import { environment } from '../../environments/environment'

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
	constructor(private authService: AuthService) { }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// add auth header with jwt if user is logged in and request is to the api url
		const currentUser = this.authService.getCurrentUser
		const isLoggedIn = currentUser && currentUser.user.accessToken

		const isApiUrl =
			request.url.startsWith(environment.customersApiUrl) &&
			!request.url.includes('/theme/') &&
			!request.url.includes('/Venue/') &&
			!request.url.includes('dine-in/menu/')

		if (isLoggedIn && isApiUrl) {
			request = request.clone({
				setHeaders: {
					Authorization: `Bearer ${currentUser.user.accessToken}`,
				},
			})
		}

		return next.handle(request)
	}
}
