import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'

import { of } from 'rxjs'
import { map, catchError, exhaustMap } from 'rxjs/operators'

import { User } from '../../interfaces/IUserData'

import { AuthService } from '../../services/auth.service'

import { userLoad, userSuccess, userError, userUpdate } from '../actions/user.action'

@Injectable()
export class UserEffects {
	constructor(private actions$: Actions, private authService: AuthService) {}

	loadUser$ = createEffect(() =>
		this.actions$.pipe(
			ofType(userLoad),
			exhaustMap(action =>
				this.authService.login(action).pipe(
					map(user => {
						return userSuccess({ payload: { ...user } })
					}),
					catchError(error => of(userError(error)))
				)
			)
		)
	)

	updateUser$ = createEffect(() =>
		this.actions$.pipe(
			ofType(userUpdate),
			exhaustMap(action =>
				this.authService.updateProductUser(action.payload).pipe(
					map(user => {
						const payload: User = {
							userId: user._id,
							name: user.name || '',
							lastName: user.lastName || '',
							accessToken: this.authService.getAccessToken(),
							phone: user.phone || '',
							email: user.emails[0] || '',
							cards: user.cards || [],
						}

						return userSuccess({ payload })
					}),
					catchError(error => of(userError(error)))
				)
			)
		)
	)
}
