import { environment } from '../environments/environment'
import { SocialAnalytics, arrayAnalytics } from './array-analytics'
;(async () => {
	const urlContingencyChecker = ['/pdf/'].some(path => location.href.includes(path))

	if (!urlContingencyChecker) {
		const proprietaryAnalytics = arrayAnalytics

		const store = window.localStorage
		const location = window.location

		let storedVenueId = store.getItem('venueId') || null

		const urlChecker = ['/menu/'].some(path => location.href.includes(path))

		function addGTM(googleAnalytic: string) {
			;(function (w: Window, d: Document, s: string, l: string, i: string) {
				w[l] = w[l] || []
				w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
				const f = d.getElementsByTagName(s)[0]
				const j = d.createElement(s) as HTMLScriptElement
				const dl = l !== 'dataLayer' ? '&l=' + l : ''
				j.async = true
				j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
				f.parentNode?.insertBefore(j, f)
			})(window, document, 'script', 'dataLayer', googleAnalytic)
		}

		function addGA(googleAnalytic: string) {
			;(function (d: Document, s: string, i: string) {
				const f = d.getElementsByTagName(s)[0]
				const j = d.createElement(s) as HTMLScriptElement
				j.async = true
				j.src = 'https://www.googletagmanager.com/gtag/js?id=' + i
				f.parentNode?.insertBefore(j, f)
			})(document, 'script', googleAnalytic)

			window['dataLayer'] = window['dataLayer'] || []
			function gtag(val1: string, val2: any) {
				window['dataLayer'].push(arguments)
			}
			gtag('js', new Date())
			gtag('config.venue', googleAnalytic)
		}

		function addFB(facebookPixel: string) {
			// Inicialização do Facebook Pixel
			;(function (
				f: Window,
				b: Document,
				e: string,
				v: string,
				n: any,
				t: HTMLScriptElement,
				s: HTMLCollectionOf<Element>
			) {
				// Verificar se o pixel do Facebook já foi carregado
				if (f['fbq']) return

				// Definir função fbq
				n = f['fbq'] = function () {
					n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
				}

				// Se não houver _fbq definido, defina-o como fbq
				if (!f['_fbq']) f['_fbq'] = n

				// Configurações iniciais
				n.push = n
				n.loaded = true
				n.version = '2.0'
				n.queue = []

				// Criação do script do Facebook Pixel
				t = b.createElement(e) as HTMLScriptElement
				t.async = true
				t.src = v

				// Inserção do script antes do primeiro script encontrado no DOM
				s = b.getElementsByTagName(e)
				s[0].parentNode?.insertBefore(t, s[0])
			})(
				window,
				document,
				'script',
				'https://connect.facebook.net/en_US/fbevents.js',
				window['fbq'],
				null,
				null
			)

			// Inicialização do pixel do Facebook e rastreamento de eventos
			;(function () {
				window['fbq']('set', 'autoConfig', false, facebookPixel)
				window['fbq']('init', facebookPixel)
				window['fbq']('track', 'PageView')
			})()
		}

		async function getVenue(venueId: string) {
			const apiUrl = `${environment.liveMenuApi.host}/Venue/${venueId}`
			const response = await fetch(apiUrl)

			if (!response.ok) {
				throw new Error()
			}

			return response.json()
		}

		if (urlChecker && !location.href.includes(storedVenueId)) {
			const venueId = location.pathname.split('/')[2]

			if (/^[a-fA-F0-9]{24}$/.test(venueId)) {
				store.setItem('venueId', venueId)
				storedVenueId = venueId
			} else {
				store.removeItem('venueId')
			}
		}

		if (storedVenueId) {
			const venue = await getVenue(storedVenueId)

			if (venue.socialAnalytics) {
				const socialAnalyticsMapped: SocialAnalytics = {
					facebookPixel: venue.socialAnalytics.facebookPixel,
					googleAnalytics: venue.socialAnalytics.googleAnalytics,
					googleTagManager: venue.socialAnalytics.googleGTM,
				}
				return setAnalytic(socialAnalyticsMapped)
			}

			const analytic = proprietaryAnalytics.find(
				proprietaryAnalytic => storedVenueId === proprietaryAnalytic.venueId
			)

			if (analytic) {
				setAnalytic(analytic)
			}
		}

		function setAnalytic(analytics: SocialAnalytics): void {
			if (analytics.googleAnalytics && analytics.googleAnalytics !== '') {
				addGA(analytics.googleAnalytics)
			}

			if (analytics.facebookPixel && analytics.facebookPixel !== '') {
				addFB(analytics.facebookPixel)
			}

			if (analytics.googleTagManager && analytics.googleTagManager !== '') {
				addGTM(analytics.googleTagManager)
			}
		}
	}
})()
