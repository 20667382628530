import { Venue } from './../../services/venue.service'
import { venueActionTypes } from '../actions/venue.action'

export interface VenueState {
	readonly venue: Venue
}

const initialState: Venue = {
	loading: true,
	_id: null,
	parent: null,
	address: {
		state: null,
		city: null,
		neighborhood: null,
	},
	name: {
		pt: null,
		en: null,
		fr: null,
		es: null,
	},
	liveMenuPro: false,
}

export function VenueReducer(state: Venue = initialState, action): Venue {
	switch (action.type) {
		case venueActionTypes.venueSuccess:
			return {
				...state,
				...action.payload,
			}
		case venueActionTypes.venueError:
			return state
		case venueActionTypes.venueClear:
			return initialState
		default:
			return state
	}
}
