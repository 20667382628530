export const environment = {
	production: true,
	imageServiceUrl: '//tagmeimages.azureedge.net',
	thumbsImage: '//tagmeimages.azureedge.net/?q=70&url=https://tagmepub.azureedge.net/pubimg/thumbs/',
	imageUrl: '//tagmeimages.azureedge.net/?q=70&url=https://tagmepub.azureedge.net/pubimg/',
	fileUrl: '//tagmeimages.azureedge.net/?q=70&url=https://tagmepub.azureedge.net/pubfiles/',
	newLiveMenu: 'https://beta.livemenu.app',

	reservationWidgetApi: {
		apitoken: 'tulioEXbodeBaLaGaP@iC@riDEbridgesCaMiLofredmercuryCAIOlouroM@QuINAMINHA',
		host: 'https://reservation-widget-api.azurewebsites.net',
	},

	liveMenuApi: {
		host: 'https://customers.tagme.com.br/dine-in',
	},

	brasPag: {
		orgId: 'k8vif92e',
		headUrl: 'https://h.online-metrix.net/fp/tags.js?',
		iframeUrl: 'https://h.online-metrix.net/fp/tags?',
	},

	pusher: {
		appId: '164937',
		key: '6d35ae2f432616d5c973',
		secret: '1d95c593e34ca25326a6',
		cluster: 'mt1',
	},

	customersApiUrl: 'https://customers.tagme.com.br',

	encryptCardPublicKey:
		'LS0tLS1CRUdJTiBQVUJMSUMgS0VZLS0tLS0KTUlHZk1BMEdDU3FHU0liM0RRRUJBUVVBQTRHTkFEQ0JpUUtCZ1FEcHlNMmY1S0daMFVMcGZBWTRjUmc2ZUhHSApOaFFPYjJSeVE0NHY3Y1hVVTFtZTBYNjNMNHlUMHVyMU5mWDRmTGQ5NkxJdFpUaE9ORjdPSE9scmJjeVFzaU1PCi9HUWREYmJEa1EwVktLQzJ1UzRZaUs4UTV0S3Y5d1NiMVZwNmxUdGZqZFhXeG5McVhQd3RpRFYyV2JmbVVwYzYKRE8vTUUzYTBsd01nM2dmcXVRSURBUUFCCi0tLS0tRU5EIFBVQkxJQyBLRVktLS0tLQ==',

	sentryDsn: 'https://99a73509411546e180c5e63f35f67d1b@o258861.ingest.sentry.io/6196452',

	wsUrl: 'https://customers.tagme.com.br/order',

	waiterUrl: 'https://waiter.tagme.com.br',
}
