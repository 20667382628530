import { of } from 'rxjs'
import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { map, catchError, exhaustMap } from 'rxjs/operators'

import { TableOrderService } from '../../services/tableOrder.service'

import { initialState } from '../../interfaces/livemenu-table-order.interface'

import { tableOrderLoad, tableOrderSuccess, tableOrderFail } from '../actions/tableOrder.action'

@Injectable()
export class TableOrderEffects {
	constructor(private actions$: Actions, private tableOrderService: TableOrderService) {}

	tableOrderLoad$ = createEffect(() =>
		this.actions$.pipe(
			ofType(tableOrderLoad),
			exhaustMap(action =>
				this.tableOrderService.getOrdersFromTable(action.venueId, action.tableId).pipe(
					map(tableOrder => {
						if (tableOrder && tableOrder.status !== 'Closed') {
							return tableOrderSuccess({
								payload: {
									loading: false,
									table: tableOrder,
									updatedAt: new Date(),
								},
							})
						} else {
							return tableOrderSuccess({
								payload: {
									loading: false,
									table: {
										...initialState.table,
										table: action.tableId,
									},
									updatedAt: new Date(),
								},
							})
						}
					}),
					catchError(error => of(tableOrderFail(error)))
				)
			)
		)
	)
}
