import { initialState, TableOrderState } from '../../interfaces/livemenu-table-order.interface'

import { tableOrderActionTypes } from '../actions/tableOrder.action'

/**Main reducer */
export function TableOrderReducer(state: TableOrderState = initialState, action: any): TableOrderState {
	switch (action.type) {
		case tableOrderActionTypes.tableOrderLoad:
			return {
				...state,
				loading: true,
			}
		case tableOrderActionTypes.tableOrderSuccess:
			return {
				...action.payload,
			}
		case tableOrderActionTypes.tableOrderUpdate:
			return {
				loading: false,
				table: action.tableOrder,
				updatedAt: new Date(),
			}
		case tableOrderActionTypes.tableOrderSetCurrentTable:
			return {
				loading: false,
				table: {
					...state.table,
					table: action.table,
				},
				updatedAt: new Date(),
			}
		case tableOrderActionTypes.tableOrderClear:
			return initialState
		default:
			return state
	}
}
