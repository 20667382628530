import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'

import { Observable } from 'rxjs'
import { skipWhile, take } from 'rxjs/operators'

import { Store } from '@ngrx/store'
import { TranslateService } from '@ngx-translate/core'

import { environment } from '../../environments/environment'

import { ThemeState } from '../store/reducers/theme.reducers'
import { themeLoad, themeSuccess } from '../store/actions/theme.action'

interface WeekdayDisplayInterface {
	weekday: Number
	startTime: string
	endTime: string
}

export interface BannerInterface {
	_id?: string
	imageUrl?: string
	thirkyUrl?: string
	bannerTitle?: string
	redirectTo?: string
	isActive: Boolean
	schedule?: [WeekdayDisplayInterface]
	priorityOrder: Number
	isGif?: boolean //Wait for changes in the backend to possibly include or change props
}

export interface Theme {
	loading?: boolean
	venue: string

	color?: {
		main?: string
		background?: string
		accentBackground?: string
		border?: string
		heading?: string
		text?: string
		price?: string
		featuredItems?: {
			heading: {
				enabled: boolean
				color?: string
			}
			paragraph: {
				enabled: boolean
				color?: string
			}
			background: {
				enabled: boolean
				color?: string
			}
			backgroundText: {
				enabled: boolean
				color?: string
			}
			border: {
				enabled: boolean
				color?: string
			}
			text: {
				enabled: boolean
				color?: string
			}
		}
	}
	logo?: {
		vertical?: string
		horizontal?: string
	}
	inspirationalImage?: {
		small?: string
		monochromatic?: string
		large?: string
	}
	banners?: {
		small?: string[]
		large?: string[]
		list?: BannerInterface[]
	}
	typeImageSpotlight: string
	typeHeader?: string
	menuLayout: string
	updated_at: string
}

@Injectable({
	providedIn: 'root',
})
export class ThemeService {
	public theme = {} as Theme
	private header = {
		headers: new HttpHeaders({
			Accept: '*/*',
			'Content-Type': 'application/json',
		}),
	}

	theme$: Observable<Theme> = this.store.select('theme').pipe(
		skipWhile(theme => !theme.venue),
		take(1)
	)

	private hideBanner = false

	constructor(
		private _http: HttpClient,
		private store: Store<ThemeState>,
		private _translateService: TranslateService
	) {
		this.theme$.subscribe(theme => {
			this.setTheme(theme)
		})
	}

	public getThemeFromVenue(venueId: string): Observable<any> {
		return this._http.get<any>(`${environment.liveMenuApi.host}/theme/${venueId}`, this.header)
	}

	firstLoadTheme(venueId: string) {
		return fetch(`${environment.liveMenuApi.host}/theme/${venueId}`)
			.then(response => {
				return response?.json()
			})
			.catch(error => {
				console.error(error)
				//console.error(this._translateService.instant('themeLoadingError', venueId), error)
			})
	}

	public getTheme() {
		return this.theme
	}

	public loadThemeFromVenue(venueId: string) {
		if (Object.keys(this.getTheme()).length === 0) {
			this.getThemeFromVenue(venueId).subscribe(
				theme => {
					this.setTheme(theme)
				},
				// TODO: Create an error service/interecptor
				err => {
					if (err.status === 500) {
						console.error(this._translateService.instant('themeLoadingError', venueId), err)
					}
				}
			)
		} else {
			this.setTheme(this.theme)
		}
	}

	public setTheme(obj: Theme) {
		const themeCopy = { ...this.theme }
		this.theme = Object.assign(themeCopy, obj)

		if (!themeCopy.menuLayout) {
			themeCopy.menuLayout = 'singleMenu'
		}

		if (obj) {
			this.setCssVars(themeCopy)
			this.store.dispatch(themeSuccess({ payload: themeCopy }))
		}
	}

	public setCssVars(theme: Theme) {
		if (Object.keys(theme).length !== 0) {
			document.documentElement.style.setProperty(`--primary-color`, theme.color.main)
			document.documentElement.style.setProperty(`--background-color`, theme.color.background)
			document.documentElement.style.setProperty(`--accent-background-color`, theme.color.accentBackground)
			document.documentElement.style.setProperty(`--border-color`, theme.color.border)
			document.documentElement.style.setProperty(`--heading-color`, theme.color.heading)
			document.documentElement.style.setProperty(`--paragraph-color`, theme.color.text)
			document.documentElement.style.setProperty(`--price-color`, theme.color.price)

			if (theme.color?.featuredItems?.heading?.enabled) {
				document.documentElement.style.setProperty(
					`--featured-heading-color`,
					theme.color.featuredItems.heading.color
				)
			}

			if (theme.color?.featuredItems?.paragraph?.enabled) {
				document.documentElement.style.setProperty(
					`--featured-paragraph-color`,
					theme.color.featuredItems.paragraph.color
				)
			}

			if (theme.color?.featuredItems?.background?.enabled) {
				document.documentElement.style.setProperty(
					`--featured-color`,
					theme.color.featuredItems.background.color
				)
			}

			if (theme.color?.featuredItems?.border?.enabled) {
				document.documentElement.style.setProperty(
					`--featured-border-color`,
					theme.color.featuredItems.border.color
				)
			}

			if (theme.color?.featuredItems?.text?.enabled) {
				document.documentElement.style.setProperty(
					`--featured-text-color`,
					theme.color.featuredItems.text.color
				)
			}

			if (theme.color?.featuredItems?.backgroundText?.enabled) {
				document.documentElement.style.setProperty(
					`--featured-text-background-color`,
					theme.color.featuredItems.backgroundText.color
				)
			}
		}
	}

	loadTheme(venueId: string, isContingency?: boolean) {
		if (this.theme && !isContingency) {
			this.store.dispatch(themeSuccess({ payload: this.theme }))
		} else {
			this.store.dispatch(themeLoad({ id: venueId }))
		}
	}

	checkOriginToHideBanner(origin: string): void {
		switch (origin.toLowerCase()) {
			case 'channels':
				this.hideBanner = true
				break;

			default:
				break;
		}
	}

	getHideBanner(): boolean {
		return this.hideBanner
	}
}
