import { HttpErrorResponse } from '@angular/common/http'
import { createAction, props } from '@ngrx/store'
import { MenuState } from '../../services/menu.service'

export enum menuActionTypes {
	menuLoad = '[Menu] Menu Load',
	menuSuccess = '[Menu] Menu Load Success',
	menuError = '[Menu] Menu Load Error',
	menuClear = '[Menu] Menu Clear',
}

export const menuLoad = createAction(menuActionTypes.menuLoad, props<{ id: string }>())
export const menuSuccess = createAction(menuActionTypes.menuSuccess, props<{ payload: MenuState }>())
export const menuError = createAction(
	menuActionTypes.menuError,
	props<{ payload: { error: HttpErrorResponse } }>()
)
export const menuClear = createAction(menuActionTypes.menuClear)
