import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'

import { AppModule } from './app/app.module'

import { environment } from './environments/environment'

import { VenueService } from './app/services/venue.service'
import { ThemeService } from './app/services/theme.service'
import { LiveMenuSettingsService } from './app/services/live-menu-settings.service'

import './social-analytics/analytics'

if (environment.production) {
	enableProdMode()
}

; (async () => {
	const venueId = /^[a-fA-F0-9]{24}$/.test(location.pathname.split('/')[2])
		? location.pathname.split('/')[2]
		: localStorage.getItem('venueId')

	const venueIdChecker = /^[a-fA-F0-9]{24}$/.test(venueId)

	const venueService = VenueService.prototype
	const themeService = ThemeService.prototype
	const liveMenuSettingsService = LiveMenuSettingsService.prototype

	let hasLiveMenu = false
	let venueTheme
	let liveMenuSettings

	if (venueIdChecker) {
		hasLiveMenu = await venueService.loadLiveMenuProConfig()

		venueTheme = await themeService.firstLoadTheme(venueId).then(theme => {
			if (theme?.banners?.list.length > 0) {
				const banner = theme.banners.list.find((item) => {
					return item.priorityOrder === 0
				}) || theme.banners.list.sort((a, b) => {
					return a.priorityOrder - b.priorityOrder
				})[0]

				const src = `${environment.thumbsImage}` + banner.imageUrl + '&output=webp'

				let link = document.createElement('link')
				link.rel = 'preload'
				link.as = 'image'
				link.href = src
				document.head.append(link)
			}

			return theme
		})

		liveMenuSettings = await liveMenuSettingsService.firstLoadLiveMenuSettings(venueId)
	}

	//FOR REDUCER
	venueService.setLiveMenuProConfig({ enabled: hasLiveMenu })

	setTimeout(() =>
		//FOR APPLICATION
		platformBrowserDynamic()
			.bootstrapModule(AppModule)
			.then(ref => {
				if (venueIdChecker) {
					ref.injector.get(VenueService).setLiveMenuProConfig({ enabled: hasLiveMenu })
					ref.injector.get(ThemeService).setTheme(venueTheme)
					ref.injector.get(LiveMenuSettingsService).setLiveMenuSettings(liveMenuSettings)
				}
			})
			.catch(err => console.error(err))
	)
})()
