import { Store } from '@ngrx/store'

import { Router } from '@angular/router'
import { Injectable } from '@angular/core'
import {
	HttpEvent,
	HttpClient,
	HttpHandler,
	HttpRequest,
	HttpInterceptor,
} from '@angular/common/http'

import { catchError } from 'rxjs/operators'
import { Observable, throwError } from 'rxjs'

import { AuthService } from '../services/auth.service'

import { environment } from '../../environments/environment'

import { AppState } from '../store/reducers'

import { userRefreshToken } from '../store/actions/user.action'

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
	constructor(
		private router: Router,
		protected http: HttpClient,
		private store: Store<AppState>,
		private authService: AuthService,
	) { }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			catchError(err => {
				if (request.url.startsWith(environment.customersApiUrl) && err.status === 500) {
					this.router.navigate(['/not-found'])
				}

				if (err.status === 401) {
					if (!request.url.startsWith(environment.customersApiUrl + '/auth/login')) {
						this.authService.refreshToken()
							.then(async (response) => {
								const payload = {
									accessToken: response.token,
									refreshToken: response.refreshToken
								}

								this.store.dispatch(userRefreshToken(payload))

								// TODO: Redo the request that caused 401
								// switch (request.method.toLowerCase()) {
								// 	case 'post':
								// 		await this.http.post<any>(
								// 			request.urlWithParams,
								// 			request.body,
								// 			{
								// 				headers: {
								// 					...request.headers,
								// 					accept: 'application/json',
								// 					Authorization: `Bearer ${payload.accessToken}`,
								// 				},
								// 			}
								// 		).toPromise()
								// 		break;

								// 	default:
								// 		break;
								// }

								// return next.handle(request)
							})
							.catch((error) => {
								console.error(error)

								// TODO: INFORM TOKEN EXPIRED

								this.authService.logout()
								location.reload()
							})
					}
				}

				return throwError(err)
			})
		)
	}
}
