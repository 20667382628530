import { Observable } from 'rxjs'

import { Store } from '@ngrx/store'

import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { environment } from '../../environments/environment'

import { TableOrderSocketService } from './tableOrder.socket.service'

import { User } from '../interfaces/IUserData'
import { IProductsUsers } from '../interfaces/IProductsUsers'

import { AppState } from '../store/reducers'
import { UserState } from './../store/reducers/users.reducers'

import { clearCart } from '../store/actions/cart.action'
import { menuClear } from '../store/actions/menu.action'
import { themeClear } from '../store/actions/theme.action'
import { venueClear } from '../store/actions/venue.action'
import { tableOrderClear } from '../store/actions/tableOrder.action'
import { tableOrderPerUserClear } from '../store/actions/tableOrderPerUser.action'
import { userLogout, userRefusedToLogin, userUnsetRefusedToLogin } from '../store/actions/user.action'

@Injectable({ providedIn: 'root' })
export class AuthService {
	public currentUser
	currentUser$: Observable<UserState> = this.store.select('user')

	constructor(
		private http: HttpClient,
		private store: Store<AppState>,
		private tableOrderSocketService: TableOrderSocketService
	) {
		this.currentUser$.subscribe(state => {
			this.currentUser = state
		})
	}

	public get getCurrentUser(): UserState {
		return this.currentUser
	}

	public isUserAuthenticated(): boolean {
		return this.currentUser.isAuthenticated
	}

	login(action: { phone?: string; email?: string; code: string }): Observable<User> {
		if (action.phone) {
			return this.loginSms(action.phone, action.code)
		} else if (action.email) {
			return this.loginEmail(action.email, action.code)
		} else {
			throw new Error('ERROR_-_LOAD_USER_INVALID_DATA.')
		}
	}

	loginSms(phone: string, code: string): Observable<User> {
		const formattedPhone = phone.replace(/[^0-9.]/g, '')
		return this.http.post<any>(`${environment.customersApiUrl}/auth/login/sms`, {
			phone: formattedPhone,
			code,
			origin: 'LIVE_MENU'
		})
	}

	loginEmail(email: string, code: string): Observable<User> {
		return this.http.post<any>(`${environment.customersApiUrl}/auth/login/email`, {
			email,
			code,
			origin: 'LIVE_MENU'
		})
	}

	sendSms(phone: string, venueId: string): Observable<Response> {
		return this.http.get<any>(
			`${environment.customersApiUrl}/auth/${phone}/${venueId}/LiveMenu/sendSms`
		)
	}

	sendEmail(email: string, venueId: string): Observable<Response> {
		return this.http.get<any>(`${environment.customersApiUrl}/auth/${email}/${venueId}/LiveMenu/sendEmail`)
	}

	refreshToken(): Promise<any> {
		return this.http
			.post<any>(
				`${environment.customersApiUrl}/auth/refresh`,
				{
					refreshToken: this.currentUser.user.refreshToken,
					origin: 'LIVE_MENU'
				},
				{
					headers: {
						accept: 'application/json',
						Authorization: `Bearer ${this.currentUser.user.accessToken}`,
					},
				}
			)
			.toPromise()
	}

	updateProductUser(productUser: Partial<IProductsUsers>) {
		return this.http.put<any>(`${environment.customersApiUrl}/auth/updateProductUser`, productUser)
	}

	logout(): void {
		this.tableOrderSocketService.closeSocket()

		this.store.dispatch(userLogout())
		this.store.dispatch(clearCart())
		this.store.dispatch(tableOrderClear())
		this.store.dispatch(tableOrderPerUserClear())
		this.store.dispatch(venueClear())
		this.store.dispatch(menuClear())
		this.store.dispatch(themeClear())

		localStorage.removeItem('waiterCooldown')
	}

	refusedToLogin(login: boolean): void {
		this.store.dispatch(userRefusedToLogin({ login }))
	}

	isLoggedIn(): boolean {
		return !this.currentUser.refusedToLogin && this.currentUser.isAuthenticated
	}

	getCurrentUserId() {
		return this.currentUser?.user.userId
	}

	unsetRefusedToLogin() {
		this.store.dispatch(userUnsetRefusedToLogin())
	}

	getAccessToken = (): string => {
		return this.currentUser?.user?.accessToken ?? null
	}

	isWaiter() {
		return !!this.currentUser?.user?.waiterOrder?.waiter?._id
	}
}
