<div class="app-modal" [ngClass]="{ 'bottom-modal': bottomModal }">
	<div
		*ngIf="showCloseButton"
		(click)="close()"
		class="app-modal-close-button tagmeicon tagmeicon-close"
	></div>

	<div class="app-modal-body" [ngClass]="{ 'app-modal-body__hasFooter': showFooter }">
		<ng-content></ng-content>
	</div>
	<div class="app-modal-footer" *ngIf="showFooter">
		<ng-content select="[footer]"></ng-content>
	</div>
</div>

<div class="app-modal-background"></div>
