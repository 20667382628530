import lookup from 'socket.io-client'
import { Injectable } from '@angular/core'

import { environment } from '../../environments/environment'

import { Logger } from './logger.service'

@Injectable({ providedIn: 'root' })
export class SocketService {
	constructor(private logger: Logger) {}

	public connect(wsNamespace = '/order', opts?) {
		var url = environment.wsUrl + wsNamespace
		return this.create(url, opts)
	}

	private create(url, opts?) {
		const defaultOpts = {
			path: opts?.path,
			query: {
				// Authorization: opts?.accessToken,
				roomName: opts?.roomName,
			},
			transports: ['websocket', 'pooling'],
		}

		const wsOpts = { ...defaultOpts, ...opts }
		const ws = lookup(url, wsOpts)

		ws.on('connect', () => {
			this.logger.log('[WS] Connected')
			this.logger.log(ws)
		})

		ws.on('disconnect', reason => {
			this.logger.log('[WS] Disconnected, reason: ', reason)
			this.logger.log(ws)
		})

		ws.on('error', err => {
			this.logger.error(err)
			this.logger.log(ws)

			// retry on CLOSE_ABNORMAL - https://developer.mozilla.org/pt-BR/docs/Web/API/CloseEvent
			// if (err.code === 1006) {
			//   this.create(url, {});
			// }
		})

		return ws
	}
}
