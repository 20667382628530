import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { LoadingOverlayComponent } from './loading-overlay/loading-overlay.component'

@NgModule({
	imports: [CommonModule],
	declarations: [LoadingOverlayComponent],
	exports: [LoadingOverlayComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LoadingModule {}
