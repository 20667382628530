export const environment = {
	production: false,
	imageServiceUrl: '//tagmeImageServiceStg.azureedge.net',
	thumbsImage: '//tagmeImageServiceStg.azureedge.net/?q=70&url=https://tagmepub.azureedge.net/pubimg/thumbs/',
	imageUrl: '//tagmeImageServiceStg.azureedge.net/?q=70&url=https://tagmepub.azureedge.net/pubimg/',
	fileUrl: '//tagmeImageServiceStg.azureedge.net/?q=70&url=https://tagmepub.azureedge.net/pubfiles/',
	newLiveMenu: '',

	reservationWidgetApi: {
		host: 'https://reservation-widget-api-staging.azurewebsites.net',
		apitoken: 'tulioEXbodeBaLaGaP@iC@riDEbridgesCaMiLofredmercuryCAIOlouroM@QuINAMINHA',
	},

	brasPag: {
		orgId: '1snn5n9w',
		iframeUrl: 'https://h.online-metrix.net/fp/tags?',
		headUrl: 'https://h.online-metrix.net/fp/tags.js?',
	},

	pusher: {
		cluster: 'mt1',
		appId: '967567',
		key: '78b8cfed442200b3a298',
		secret: '45a13d9ff364d67dfc57',
	},

	encryptCardPublicKey:
		'LS0tLS1CRUdJTiBQVUJMSUMgS0VZLS0tLS0KTUlHZk1BMEdDU3FHU0liM0RRRUJBUVVBQTRHTkFEQ0JpUUtCZ1FEcHlNMmY1S0daMFVMcGZBWTRjUmc2ZUhHSApOaFFPYjJSeVE0NHY3Y1hVVTFtZTBYNjNMNHlUMHVyMU5mWDRmTGQ5NkxJdFpUaE9ORjdPSE9scmJjeVFzaU1PCi9HUWREYmJEa1EwVktLQzJ1UzRZaUs4UTV0S3Y5d1NiMVZwNmxUdGZqZFhXeG5McVhQd3RpRFYyV2JmbVVwYzYKRE8vTUUzYTBsd01nM2dmcXVRSURBUUFCCi0tLS0tRU5EIFBVQkxJQyBLRVktLS0tLQ==',

	sentryDsn: '',

	// ------------------- LOCAL ------------------- //
	// waiterUrl: 'http://localhost:4201',
	// wsUrl: 'http://localhost:3103/order',
	// customersApiUrl: 'http://localhost:3103',
	// liveMenuApi: {
	// 	host: 'http://localhost:3003/dine-in',
	// },

	// ------------------- DEV ------------------- //
	// waiterUrl: 'https://waiter-dev.tagme.com.br',
	// wsUrl: 'https://customersapi-dev.azurewebsites.net/order',
	// customersApiUrl: 'https://customersapi-dev.azurewebsites.net',
	// liveMenuApi: {
	// 	host: 'https://customersapi-dev.azurewebsites.net/dine-in',
	// },
	// menuManagerApiUrl: 'https://menumanager-dev.azurewebsites.net/live-menu',

	// ------------------- STG ------------------- //
	// waiterUrl: 'https://waiter-stg.tagme.com.br',
	// wsUrl: 'https://customersapi-stg.azurewebsites.net',
	// customersApiUrl: 'https://customersapi-stg.azurewebsites.net',
	// liveMenuApi: {
	// 	host: 'https://customersapi-stg.azurewebsites.net/dine-in',
	// },
	// menuManagerApiUrl: 'https://menumanager-stg.azurewebsites.net/live-menu',

	// ------------------- PROD ------------------- //
	waiterUrl: 'https://waiter.tagme.com.br',
	wsUrl: 'https://customers.tagme.com.br/',
	customersApiUrl: 'https://customers.tagme.com.br/',
	liveMenuApi: {
		host: 'https://customers.tagme.com.br/dine-in',
	},
	menuManagerApiUrl: 'https://menumanager.azurewebsites.net/live-menu',
}
