import { of } from 'rxjs'
import { Injectable } from '@angular/core'
import { map, catchError, exhaustMap } from 'rxjs/operators'
import { Actions, createEffect, ofType } from '@ngrx/effects'

import { TableOrderService } from '../../services/tableOrder.service'

import { initialState } from './../../interfaces/IOrdersPerUser.interface'

import {
	tableOrderPerUserFail,
	tableOrderPerUserLoad,
	tableOrderPerUserSuccess,
} from '../actions/tableOrderPerUser.action'

@Injectable()
export class TableOrderPerUserEffects {
	constructor(private actions$: Actions, private tableOrderService: TableOrderService) {}

	tableOrderPerUserLoad$ = createEffect(() =>
		this.actions$.pipe(
			ofType(tableOrderPerUserLoad),
			exhaustMap(action =>
				this.tableOrderService.getOrdersFromTableGroupByUser(action.venueId, action.tableId).pipe(
					map(ordersPerUser => {
						if (ordersPerUser && ordersPerUser[0].status !== 'Closed') {
							return tableOrderPerUserSuccess({
								payload: {
									loading: false,
									ordersPerUser,
								},
							})
						} else {
							return tableOrderPerUserSuccess({
								payload: {
									loading: false,
									ordersPerUser: [...initialState.ordersPerUser],
								},
							})
						}
					}),
					catchError(error => of(tableOrderPerUserFail(error)))
				)
			)
		)
	)
}
