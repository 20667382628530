import { IPartialProductsUsers } from './IProductsUsers'

export const initialState: TableOrderPerUserState = {
	loading: true,
	ordersPerUser: [
		{
			_id: {
				_id: null,
				name: '',
			},
			orders: [],
			totalAccepted: 0,
			totalAcceptedWithCharge: 0,
			chargeAccepted: 0,
			isToggleOpened: false,
		},
	],
}

export interface OrdersPerUser {
	_id: IPartialProductsUsers
	orders: []
	totalAccepted: number
	totalAcceptedWithCharge: number
	chargeAccepted: number
	isToggleOpened: boolean
}

export interface TableOrderPerUserState {
	loading: boolean
	ordersPerUser: Array<OrdersPerUser>
}
