import { Component, HostBinding, Input, OnInit } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'
import { LoaderService } from './../../services/loader.service';


@Component({
	selector: 'app-loading-overlay',
	templateUrl: './loading-overlay.component.html',
	styleUrls: ['./loading-overlay.component.scss'],
})
export class LoadingOverlayComponent implements OnInit {
	@Input() overlay: boolean
	@Input() show: boolean = false
	@Input() width: string = '80px'
	@Input() height: string = '80px'
	@Input() align: string = 'center'
	@Input() color: string = '#ff7f40'

	@HostBinding('attr.style')
	public get valueAsStyle(): any {
		return this.sanitizer.bypassSecurityTrustStyle(`--strokeColor: ${this.color}`)
	}

	constructor(private _loaderService: LoaderService, private sanitizer: DomSanitizer) {}

	ngOnInit() {
		if (!this.show) {
			this._loaderService.status$.subscribe((status: boolean) => {
				this.show = status
			})
		}
	}
}
