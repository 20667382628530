import { createAction, props } from '@ngrx/store'

import { LiveMenu } from '../../services/live-menu-settings.service'

export enum liveMenuActionTypes {
	liveMenuLoad = '[Live Menu] Live Menu Load',
	liveMenuSuccess = '[Live Menu] Live Menu Load Success',
	liveMenuError = '[Live Menu] Live Menu Load Error',
	liveMenuClear = '[Live Menu] Live Menu Clear',
}

export const liveMenuLoad = createAction(liveMenuActionTypes.liveMenuLoad, props<{ venueId: string }>())
export const liveMenuSuccess = createAction(
	liveMenuActionTypes.liveMenuSuccess,
	props<{ payload: LiveMenu }>()
)
export const liveMenuError = createAction(liveMenuActionTypes.liveMenuError, props<{ error: any }>())
export const liveMenuClear = createAction(liveMenuActionTypes.liveMenuClear)
