import { Router, Scroll } from '@angular/router'
import localePt from '@angular/common/locales/pt'
import { BrowserModule } from '@angular/platform-browser'
import { registerLocaleData, ViewportScroller } from '@angular/common'
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import {
	DEFAULT_CURRENCY_CODE,
	LOCALE_ID,
	NgModule,
	CUSTOM_ELEMENTS_SCHEMA,
	APP_INITIALIZER,
} from '@angular/core'

import { filter } from 'rxjs/operators'

import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'

import { TranslateModule, TranslateLoader } from '@ngx-translate/core'

import { NgxMaskModule } from 'ngx-mask'

import { environment } from '../environments/environment.prod'

import { AppComponent } from './app.component'

import { ButtonModule } from '@tagmedev/tagme-components'

import { AppRoutingModule } from './app-routing.module'
import { LoadingModule } from './shared/loading.module'
import { ModalModule } from './shared/modal/modal.module'
import { SnackbarModule } from './shared/snackbar/snackbar.module'

import { reducers, metaReducers, rootEffects } from './store/reducers'

import { JwtInterceptor } from './helpers/jwt.interceptor'
import { ErrorInterceptor } from './helpers/error.interceptor'

import { ServiceWorkerModule } from '@angular/service-worker'

import { WebpackTranslateLoader } from './helpers/webpack-translate-loader'
import { RedirectToNewLiveMenuService } from './services/redirect-to-new-live-menu.service'

registerLocaleData(localePt, 'pt')

export function initializeApp(redirectService: RedirectToNewLiveMenuService) {
	return () => redirectService.checkAndRedirect()
}

@NgModule({
	declarations: [AppComponent],
	imports: [
		ButtonModule,
		HttpClientModule,
		BrowserModule,
		AppRoutingModule,
		LoadingModule,
		SnackbarModule,
		ModalModule,
		TranslateModule.forRoot({
			defaultLanguage: 'pt-br',
			loader: {
				provide: TranslateLoader,
				useClass: WebpackTranslateLoader,
			},
		}),
		StoreModule.forRoot(reducers, { metaReducers }),
		EffectsModule.forRoot(rootEffects),
		!environment.production ? StoreDevtoolsModule.instrument({ maxAge: 25 }) : [],
		NgxMaskModule.forRoot({
			patterns: {
				'&': { pattern: new RegExp('.*') },
				A: { pattern: new RegExp('[a-zA-Z]') },
				a: { pattern: new RegExp('[a-z]') },
				'0': { pattern: new RegExp('[0-9]') },
			},
		}),
		ServiceWorkerModule.register('sw.js', {
			enabled: true,
			// Register the ServiceWorker as soon as the app is stable
			// or after 30 seconds (whichever comes first).
			registrationStrategy: 'registerWhenStable:30000',
		}),
	],
	providers: [
		{ provide: LOCALE_ID, useValue: 'pt-BR' },
		{ provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL' },
		{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
		{
			provide: APP_INITIALIZER,
			useFactory: initializeApp,
			deps: [RedirectToNewLiveMenuService],
			multi: true,
		},
	],
	bootstrap: [AppComponent],
	exports: [LoadingModule],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
	constructor(router: Router, viewportScroller: ViewportScroller) {
		router.events.pipe(filter((e): e is Scroll => e instanceof Scroll)).subscribe(e => {
			if (e.position) {
				// backward navigation
				setTimeout(() => {
					viewportScroller.scrollToPosition(e.position)
				})
			} else if (e.anchor) {
				// anchor navigation
				viewportScroller.scrollToAnchor(e.anchor)
			} else {
				// forward navigation
				viewportScroller.scrollToPosition([0, 0])
			}
		})
	}
}
